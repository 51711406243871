import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateUserClients} from "../../reducer/settingsSlice";
import ajaxloader from "../../images/ajax-loader.gif";
import {loadClients, selectClientState} from "../../reducer/clientSlice";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import store from "../../store/store";
import {RESET_ACTION} from "../canopia/CanopiaUtils";
import {Check} from "react-bootstrap-icons";
import THSort from "../canopia/THSort";
import {loadUserSettings, selectSettingsState} from "../../reducer/settingsSlice";

export default function UserClientSettings(props) {

    const dispatch = useDispatch();

    const {clientConfigs} = useSelector(selectClientState);
    const {tgtData} = useSelector(selectSettingsState);

    const [selectedUserClients, setSelectedUserClients] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [showValidationModal, setShowValidationModal] = useState(false);
    const [loadStatus, setLoadStatus] = useState("idle");
    const [loadMessage, setLoadMessage] = useState("");
    const [updateStatus, setUpdateStatus] = useState("idle");
    const [updateMessage, setUpdateMessage] = useState("");

    const targetSort = "userSettings";

    const load = useCallback(() => {
        dispatch(loadClients());
        dispatch(loadUserSettings());
    }, [dispatch]);

    // Load all the clients to populate the dropdown (done only once)
    useEffect(() => {
        if (loadStatus === "idle") {
            setLoadStatus("pending");
            try {
                load();
            } catch (err) {
                setLoadStatus("error");
                setLoadMessage("An error occurred while loading the page");
            } finally {
                setLoadStatus("success");
            }
        }
    }, [loadStatus, load]);

    useEffect(() => {
        if (clientConfigs && tgtData) {
            setSelectedUserClients(clientConfigs.map(client => client.clientWrapper.id));
            setSelectAllChecked(clientConfigs.length === tgtData.length);
        }
    }, [clientConfigs, tgtData]);

    const areAllUsersSelected = selectedUserClients.length === tgtData.length;

    const addRemoveClient = (e) => {
        let clientId = Number.parseInt(e.target.name);
        let checked = e.target.checked;
        setSelectedUserClients([...selectedUserClients, clientId]);
        if (!checked) {
            setSelectedUserClients(selectedUserClients.filter(item => item !== clientId));
            setSelectAllChecked(false);
        } else {
            // Add 1 as the tmp var is not yet set
            setSelectAllChecked(selectedUserClients.length + 1 >= tgtData.length);
        }
    };

    const selectAllClients = (e) => {
        let checked = e.target.checked;
        if (checked) {
            setSelectedUserClients(tgtData.map(client => client.clientConfig.clientWrapper.id));
        } else {
            setSelectedUserClients([]);
        }
        setSelectAllChecked(checked);
    };

    const submit = () => {
        if (selectedUserClients.length === 0) {
            showModal();
        } else {

            let inlineUserClients = '';
            let i = 0;
            selectedUserClients.forEach(clientId => {
                inlineUserClients += (i === 0 ? "" : "&") + "userClients=" + clientId;
                i++;
            });

            setUpdateStatus("pending");
            save(inlineUserClients).then(
                () => {
                    setUpdateStatus("success");
                    setUpdateMessage("Your changes have been saved");
                    setTimeout(() => setUpdateMessage(null), 3000);
                },
                error => {
                    setUpdateStatus("error");
                    setUpdateMessage("An error occurred while saving your changes");
                }
            );
        }
    };

    async function save(inlineUserClients) {
        // Save the user clients
        await dispatch(updateUserClients({userClients: inlineUserClients}));

        // Must flush the store to reset the "Portfolio" tab
        store.dispatch(RESET_ACTION);

        // Reload the user clients
        await load();
    }

    const showModal = () => {
        setSelectAllChecked(areAllUsersSelected);
        setShowValidationModal(true);
    };

    const hideModal = () => {
        setShowValidationModal(false);
    };

    let content;
    if (loadStatus === "error") {
        content = <div className="main-content">
            <header className="jumbotron">
                <h3 className='canopia'>Error</h3>
                <p>{loadMessage}</p>
            </header>
        </div>
    } else if (loadStatus !== "success") {
        content = <div><br/><img alt="loading" src={ajaxloader}/></div>;
    } else {
        const check = <Check size={'1.6em'} color={'#11bb44'}/>;
        content = <>
            <span className={'client-title'}>Client Access</span>
            <div style={{paddingTop: '20px'}}>
                <p>
                    Select the clients you would like to see in the drop down of the Portfolio screen.
                </p>
                <div className="form-group float-left" style={{marginBottom: "30px", marginRight: "20px"}}>
                    <button
                        className="btn btn-primary btn-block"
                        disabled={updateStatus === 'pending' || selectedUserClients.size === 0}
                        onClick={submit}
                    >
                        {updateStatus === 'pending' && (
                            <span className="spinner-border spinner-border-sm"/>
                        )}
                        <span>Save</span>
                    </button>
                </div>
                {updateMessage && (
                    <div className="form-group float-left">
                        <div className={updateStatus === "success"
                            ? "alert alert-success"
                            : "alert alert-danger"} role="alert">
                            {updateMessage}
                        </div>
                    </div>
                )}
                <br/>
                <Table hover size="sm" className="font-size-small sticky-thc">
                    <thead>
                    <tr>
                        <th className={"canopia2 background-light"}>
                            <THSort target={targetSort} colName="clientConfig.clientWrapper.displayName" label="Client"/>
                        </th>
                        <th className={"canopia2 background-light"}>
                            <abbr title="Select all">
                                <input type="checkbox" name={"all-clients-cb"} onChange={selectAllClients} checked={selectAllChecked}/>
                            </abbr>
                        </th>
                        {/*<th className={"canopia2 background-light"}>Type</th>*/}
                        {/*<th className={"canopia2 background-light"}>Account manager</th>*/}
                        <th className={"canopia2 background-light canopia-border-left"}>
                            <THSort target={targetSort} colName="clientConfig.clientType" label="Type"/>
                        </th>
                        <th className={"canopia2 background-light"}>
                            <THSort target={targetSort} colName="clientConfig.clientBrand" label="Logo"/>
                        </th>
                        <th className={"canopia2 background-light"}>
                            <THSort target={targetSort} colName="clientConfig.clientWrapper.currency" label="Ccy"/>
                        </th>
                        <th className={"canopia2 background-light"}>
                            <THSort target={targetSort} colName="clientConfig.periodicityKey" label="Periodicity"/>
                        </th>
                        <th className={"canopia2 background-light"}>
                            <THSort target={targetSort} colName="clientConfig.showDirectLines" label="Direct Lines"/>
                        </th>
                        <th className={"canopia2 background-light"}>
                            <THSort target={targetSort} colName="clientConfig.showRealEstatePositions" label="Real Estate"/>
                        </th>
                        <th className={"canopia2 background-light"}>
                            <THSort target={targetSort} colName="clientConfig.showCo2Data" label="CO2"/>
                        </th>
                        <th className={"canopia2 background-light"}>
                            <THSort target={targetSort} colName="clientConfig.showNetZeroData" label="Net Zero"/>
                        </th>
                        <th className={"canopia2 background-light canopia-border-left"}>
                            <THSort target={targetSort} colName="lastPublished" label="Last published"/>
                        </th>
                        <th className={"canopia2 background-light"}>
                            <THSort target={targetSort} colName="lastRun" label="Last run"/>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {tgtData.map(setting => {
                        const config = setting.clientConfig;
                        const clientWrapper = config.clientWrapper;
                        const clientId = clientWrapper.id;
                        let checked = selectedUserClients.includes(clientId);

                        return <tr key={clientId}>
                            <td className={"nowrap"}>{clientWrapper.displayName}</td>
                            <td className={"nowrap"}>
                                <abbr title="Show/hide this client in the Portfolio screen">
                                    <input
                                        key={clientId}
                                        type="checkbox"
                                        name={clientId}
                                        id={clientId}
                                        onChange={addRemoveClient}
                                        checked={checked}
                                    />
                                </abbr>
                            </td>
                            {/*<td className={"nowrap"}>{client.}</td>*/}
                            <td className={"nowrap canopia-border-left"}>{config.clientType}</td>
                            <td className={"nowrap"}>{config.clientBrand}</td>
                            <td className={"nowrap"}>{config.clientCurrency}</td>
                            <td className={"nowrap"}>{config.periodicityKey}</td>
                            <td className={"nowrap"}>{config.showDirectLines && check}</td>
                            <td className={"nowrap"}>{config.showRealEstatePositions && check}</td>
                            <td className={"nowrap"}>{config.showCo2Data && check}</td>
                            <td className={"nowrap"}>{config.showNetZeroData && check}</td>
                            <td className={"nowrap canopia-border-left"}>{setting.lastPublished}</td>
                            <td className={"nowrap"}>{setting.lastRun}</td>
                        </tr>
                    })}
                    </tbody>
                </Table>
            </div>
            <Modal size="sm" show={showValidationModal} onHide={hideModal}>
                <Modal.Header closeButton>
                    <Modal.Title><h3 className={'canopia'}>Information</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6 className={'canopia2'} style={{marginBottom: '30px'}}>Please select at least one client</h6>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={hideModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    }

    return (
        <div className={"main-content"}>
            {content}
        </div>
    );
}
