import {authHeader} from './auth-header';
import {CONTEXT_URL} from "../containers/canopia/CanopiaUtils";

const API_URL = CONTEXT_URL + "api/main/";

class DownloadService {

    getExcelReport(params) {
        return fetch(API_URL + "export?clientId=" + params.clientId + "&dateStr=" + params.date, {headers: authHeader(), cache: 'no-store'})
            .then(response => {
                    let contentType = response.headers.get("content-type");

                    if (contentType) {
                        if (contentType.indexOf("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") !== -1) {
                            return response.blob();
                        } else if (contentType.indexOf("application/json") !== -1) {
                            return response.json();
                        } else {
                            console.log("Unsupported content type found: " + contentType);
                        }
                    }
                }
            )
            .catch(error => error)
    }

    getPdfReport(params) {
        // For IE
        // if (!String.prototype.replaceAll) {
        //     String.prototype.replaceAll = function (str, newStr) {
        //         // If a regex pattern
        //         if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
        //             return this.replace(str, newStr);
        //         }
        //         // If a string
        //         return this.replace(new RegExp(str, 'g'), newStr);
        //     };
        // }

        // let cat = params.category.replaceAll('&', encodeURIComponent('&'));
        // cat = cat.replaceAll('+', encodeURIComponent('+'));
        let cat = encodeURIComponent(params.category);
        return fetch(API_URL + "download?clientId=" + params.clientId + "&dateStr=" + params.date + "&category=" + cat, {headers: authHeader(), cache: 'no-store'})
            .then(response => {
                    let contentType = response.headers.get("content-type");

                    if (contentType) {
                        if (contentType.indexOf("application/pdf") !== -1) {
                            return response.blob();
                        } else if (contentType.indexOf("application/json") !== -1) {
                            return response.json();
                        } else {
                            console.log("Unsupported content type found: " + contentType);
                        }
                    }
                }
            )
            .catch(error => error)
    }

    getArchive(params) {
        return fetch(API_URL + "archive?clientId=" + params.clientId + "&dateStr=" + params.date, {headers: authHeader(), cache: 'no-store'})
            .then(response => {
                    let contentType = response.headers.get("content-type");

                    if (contentType) {
                        if (contentType.indexOf("application/zip") !== -1) {
                            return response.blob();
                        } else if (contentType.indexOf("application/json") !== -1) {
                            return response.json();
                        } else {
                            console.log("Unsupported content type found: " + contentType);
                        }
                    }
                }
            )
            .catch(error => error)
    }

    getHistory(params) {
        return fetch(API_URL + "history?clientId=" + params.clientId, {headers: authHeader(), cache: 'no-store'})
            .then(response => {
                    let contentType = response.headers.get("content-type");

                    if (contentType) {
                        if (contentType.indexOf("text/csv") !== -1) {
                            return response.blob();
                        } else {
                            console.log("Unsupported content type found: " + contentType);
                        }
                    }
                }
            )
            .catch(error => error)
    }

}

const downloadService = new DownloadService();
export default downloadService;
