import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {authHeader} from "../services/auth-header";
import {CONTEXT_URL, sort} from "../containers/canopia/CanopiaUtils";

const API_URL = CONTEXT_URL + "api/settings/";

const settingsInitialSortStatus = {
    "clientConfig.clientWrapper.displayName": "asc",
    "clientConfig.clientType": "asc",
    "clientConfig.clientBrand": "asc",
    "clientConfig.clientWrapper.currency": "asc",
    "clientConfig.periodicityKey": "asc",
    "clientConfig.showDirectLines": "asc",
    "clientConfig.showRealEstatePositions": "asc",
    "clientConfig.showCo2Data": "asc",
    "clientConfig.showNetZeroData": "asc",
    "lastPublished": "asc",
    "lastRun": "asc",
}

const initialState = {
    tgtData: [], // The displayed ones, filtered, sorted, ...
    sortStatus: settingsInitialSortStatus,
    sortCol: "clientConfig.clientType",
    status: "idle",
    error: null
}

export const loadUserSettings = createAsyncThunk(
    'settings/user-settings',
    async () =>
        fetch(API_URL + "user-settings", {headers: authHeader(), cache: 'no-store'})
            .then(response => {
                let contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json();
                } else {
                    console.log("The content type is not JSON, found " + contentType);
                }
            })
            .then(json => json)
            .catch(error => error)
);

export const updateUserClients = createAsyncThunk(
    'settings/update-clients',
    async (params) =>
        fetch(API_URL + "update-user-clients?" + params.userClients,
            {headers: authHeader(), method: "POST", cache: 'no-store'})
            .then(response => {
                let contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json();
                } else {
                    console.log("The content type is not JSON, found " + contentType);
                }
            })
            .then(json => json)
            .catch(error => error)
);

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        sortSettings: (state, action) => {
            // load the portfolio of the selected client
            let col = action.payload.col;

            sort(state, col, settingsInitialSortStatus);
        },
    },
    extraReducers: {
        [loadUserSettings.pending]: (state, action) => {
            state.status = 'loading';
        },
        [loadUserSettings.fulfilled]: (state, action) => {
            let payload = action.payload;
            if (payload.status) {
                state.status = 'error';
                state.error = payload;
            } else if (payload.message === 'Failed to fetch') {
                state.status = 'error';
                state.error = {
                    error: 'Service unavailable', //
                    message: 'We apologize for the inconvenience, our team is working on solving the issue. ' + //
                        'Please try to come back in a few minutes. Thank you for your patience.'
                };
            } else {
                state.status = 'success';
                state.tgtData = payload["data"];
            }
        },
        [loadUserSettings.rejected]: (state, action) => {
            state.status = 'error';
            state.error = action.payload;
        },
        [updateUserClients.pending]: (state, action) => {
            state.updateStatus = 'loading';
        },
        [updateUserClients.fulfilled]: (state, action) => {
            let payload = action.payload;
            if (payload.status) {
                state.updateStatus = 'error';
                state.updateError = payload;
            } else if (payload.message === 'Failed to fetch') {
                state.updateStatus = 'error';
                state.updateError = {
                    error: 'Service unavailable', //
                    message: 'We apologize for the inconvenience, our team is working on solving the issue. ' + //
                        'Please try to come back in a few minutes. Thank you for your patience.'
                };
            } else {
                state.updateStatus = 'success';
            }
        },
        [updateUserClients.rejected]: (state, action) => {
            state.updateStatus = 'error';
            state.updateError = action.payload;
        }
    }
});

export const {
    sortSettings
} = settingsSlice.actions;


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectSettingsState = state => state.settings;

export default settingsSlice.reducer;