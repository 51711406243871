import React, {useCallback, useLayoutEffect, useRef, useState} from "react";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4charts from "@amcharts/amcharts4/charts";
import {BM, CHART_DATA_SCALE, CHART_TYPES, getIconPath, LEGENDS, PF, THEMES, TOTAL} from "../CanopiaUtils";
import {addAmcharts4Licence, getChartData} from "./ChartUtils";
import DetailHistoChart from "./DetailHistoChart";
import DetailSubChart from "./DetailSubChart";
import TopPosition from "../TopPosition";
import LabelWithTooltip from "../LabelWithTooltip";
import {IconContext} from "react-icons";
import {AiOutlineDownload} from "react-icons/ai";

export default function ESGMetricsDetailChart(props) {

    const pfRow = props.pfRow;
    const bmRow = props.bmRow;
    const theme = props.theme;
    const subTheme = props.subTheme;
    const subThemeValue = props.subThemeValue;
    const changeSubThemeValue = props.changeSubThemeValue;
    // const clearSubThemeValue = props.clearSubThemeValue;
    const changePfKey = props.changePfKey;
    const chartType = props.chartType;
    const pfName = props.pfName;
    const bmName = props.bmName;
    const date = props.date;
    const maxDate = props.maxDate;
    const isReview = props.isReview;

    const data = props.data;
    const chartData = data.data;

    const chartDiv = "chartdiv";

    const tgtChart = useRef(null);

    const [dataScale, setDataScale] = useState(CHART_DATA_SCALE[1]);

    function changeDataScale(scale) {
        setDataScale(scale);
    }

    const createCurrentChart = useCallback(isReview => {
        addAmcharts4Licence();
        am4core.useTheme(am4themes_animated);
        am4core.options.autoDispose = true;
        let chart = am4core.create(chartDiv, am4charts.XYChart);

        // Get the data at the right date
        let dataAtDate = getChartData(chartData, date);

        // Transform the source data from a single object containing all sectors to a list of objects per sector
        // {
        //     date: '2022-12-31',
        //     BM_tobacco: 0.01,
        //     PF_tobacco: 0.02,
        //     BM_Alcohol: 0.001,
        // }
        //
        // {
        //      category: Tobacco,
        //      Portfolio: 0.02,
        //      BM Global: 0.01
        // }
        let newData = [];
        const dateField = "date";
        let fields = [];
        Object.keys(dataAtDate).forEach(field => {
            if (field !== dateField) {
                const tgtField = field.replace(PF, '').replace(BM, '');
                if ((subThemeValue === TOTAL && tgtField !== TOTAL) || (subThemeValue !== TOTAL && tgtField === subThemeValue)) {
                    if (!fields.includes(tgtField)) {
                        fields.push(tgtField);
                    }
                }
            }
        });
        fields.forEach(field => {
            newData.push({
                category: field,
                pf: dataAtDate.hasOwnProperty(PF + field) ? dataAtDate[PF + field] : 0.0,
                bm: dataAtDate.hasOwnProperty(BM + field) ? dataAtDate[BM + field] : 0.0,
                // bullet: getIconPath("ALC", 'bad')
                bullet: getIconPath(field, 'bad')
            });
        });
        chart.data = newData;

        if (isReview) {
            chart.exporting.menu = new am4core.ExportMenu();
            chart.exporting.filePrefix = pfName + "_" + subTheme.labelForExport;
        } else {
            let options = chart.exporting.getFormatOptions("svg");
            options.quality = 1;
            chart.exporting.setFormatOptions("svg", options);
        }

        chart.legend = new am4charts.Legend();
        let legend = chart.legend;
        legend.position = 'top';
        legend.valign = 'top';
        legend.maxHeight = 150;
        legend.marginTop = 10;
        legend.marginBottom = 20;
        legend.x = 50;
        legend.itemContainers.template.paddingTop = 2;
        legend.itemContainers.template.paddingBottom = 2;
        let markerTemplate = legend.markers.template;
        markerTemplate.width = 10;
        markerTemplate.height = 10;

        // Axis
        let xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        xAxis.dataFields.category = 'category'
        // xAxis.cursorTooltipEnabled = true;
        // xAxis.tooltip.background.fill = am4core.color("#1D416D");
        //

        xAxis.renderer.cellStartLocation = 0.1
        xAxis.renderer.cellEndLocation = 0.9
        xAxis.renderer.grid.template.location = 0;
        if (fields.length > 1) {
            xAxis.renderer.labels.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
            xAxis.renderer.labels.template.html = "<span class='chart-label-hover'>{category}</span>";
            // Click on the labels to focus on the sector
            xAxis.renderer.labels.template.events.on("hit", function (ev) {
                let data = ev.target.dataItem;
                changeSubThemeValue(data.category);
            });
        }

        let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
        yAxis.numberFormatter.numberFormat = subTheme.formatAxis;
        yAxis.min = 0;
        yAxis.extraMax = 0.15;

        // PF Series
        let pfSeries = chart.series.push(new am4charts.ColumnSeries());
        pfSeries.dataFields.valueY = 'pf';
        pfSeries.dataFields.categoryX = 'category';
        pfSeries.name = pfName;
        pfSeries.stroke = am4core.color(subTheme.colors[0]);
        // pfSeries.columns.template.width = am4core.percent(95);
        pfSeries.columns.template.fill = pfSeries.stroke;
        // pfSeries.columns.template.events.on("hit", function (ev) {
        //     let data = ev.target.dataItem.dataContext;
        //     subThemeValueFunc(data.category);
        // });

        let pfBullet = pfSeries.bullets.push(new am4charts.LabelBullet());
        pfBullet.interactionsEnabled = false;
        // pfBullet.dy = 30;
        pfBullet.dy = -10;
        pfBullet.label.text = "{valueY.formatNumber('" + subTheme.formatValue + "')}";
        pfBullet.label.fill = am4core.color('#1D416D');
        // pfBullet.label.fill = am4core.color('#fff');

        // let image = pfBullet.createChild(am4core.Image);
        // image.horizontalCenter = "middle";
        // image.verticalCenter = "bottom";
        // image.dy = 0;
        // image.propertyFields.href = "bullet";
        // image.tooltipText = pfSeries.columns.template.tooltipText;
        // image.propertyFields.fill = "color";
        // image.filters.push(new am4core.DropShadowFilter());

        if (bmName) {
            // BM Series
            let bmSeries = chart.series.push(new am4charts.ColumnSeries());
            bmSeries.dataFields.valueY = 'bm';
            bmSeries.dataFields.categoryX = 'category';
            bmSeries.name = bmName;
            bmSeries.stroke = am4core.color('#a0a0a0');
            bmSeries.columns.template.fill = bmSeries.stroke;

            let bmBullet = bmSeries.bullets.push(new am4charts.LabelBullet());
            bmBullet.interactionsEnabled = false;
            // bmBullet.dy = 30;
            bmBullet.dy = -10;
            bmBullet.label.text = "{valueY.formatNumber('" + subTheme.formatValue + "')}";
            // bmBullet.label.fill = am4core.color('#ffffff');
            bmBullet.label.fill = am4core.color('#53565a');

            pfSeries.events.on("hidden", arrangeColumns);
            pfSeries.events.on("shown", arrangeColumns);

            bmSeries.events.on("hidden", arrangeColumns);
            bmSeries.events.on("shown", arrangeColumns);

            function arrangeColumns() {

                let series = chart.series.getIndex(0);

                let w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
                if (series.dataItems.length > 1) {
                    let x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
                    let x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
                    let delta = ((x1 - x0) / chart.series.length) * w;
                    if (am4core.isNumber(delta)) {
                        let middle = chart.series.length / 2;

                        let newIndex = 0;
                        chart.series.each(function (series) {
                            if (!series.isHidden && !series.isHiding) {
                                series.dummyData = newIndex;
                                newIndex++;
                            } else {
                                series.dummyData = chart.series.indexOf(series);
                            }
                        })
                        let visibleCount = newIndex;
                        let newMiddle = visibleCount / 2;

                        chart.series.each(function (series) {
                            let trueIndex = chart.series.indexOf(series);
                            let newIndex = series.dummyData;

                            let dx = (newIndex - trueIndex + middle - newMiddle) * delta

                            series.animate({
                                property: "dx",
                                to: dx
                            }, series.interpolationDuration, series.interpolationEasing);
                            series.bulletsContainer.animate({
                                property: "dx",
                                to: dx
                            }, series.interpolationDuration, series.interpolationEasing);
                        })
                    }
                }
            }
        }
    }, [date, chartData, chartDiv, pfName, bmName, subThemeValue, changeSubThemeValue, subTheme]);

    useLayoutEffect(() => {
        createCurrentChart(isReview);
    }, [createCurrentChart, isReview, chartType]);

    const exportAsImage = () => {
        if (tgtChart) {
            tgtChart.current.exporting.filePrefix = pfName + "_" + subTheme.labelForExport;
            tgtChart.current.exporting.export("svg");
        }
    }

    let theChart;
    // const showTopPos = [THEMES[1].key, THEMES[3].key, THEMES[4].key].includes(theme.key);
    // const tgtChartType = showTopPos ? chartType : CHART_TYPES[0];
    switch (chartType.key) {
        case 'detail':
            theChart = <>
                <div className={'canopia2 detail-sub-title'}>
                    {subThemeValue === TOTAL ?
                        <LabelWithTooltip label={'Exposure to ' + subTheme.label}
                                          text={LEGENDS['detail.exposure']}/>
                        : 'Exposure to ' + subThemeValue}
                </div>
                <div style={{display: "grid", gridTemplateColumns: "1fr 30px"}}>
                    <p/>
                    {!isReview && <span className={'label-hover'} onClick={exportAsImage}>
                        <IconContext.Provider value={{className: "react-icons canopia2", size: "1.4em"}}>
                            <AiOutlineDownload/>
                        </IconContext.Provider>
                    </span>}
                </div>
                <div id={chartDiv} className={subThemeValue === TOTAL ? 'chart-sector-all' : 'chart-sector-single'}/>
                <div style={{margin: '20px 0'}}>
                    <DetailSubChart pfRow={pfRow} bmRow={bmRow} data={data} theme={theme} subTheme={subTheme}
                                    subThemeValue={subThemeValue}
                                    dataScale={dataScale}
                                    changeDataScale={changeDataScale}
                                    changePfKey={changePfKey}
                                    pfName={pfName} bmName={bmName} date={date} maxDate={maxDate} isReview={isReview}/>
                    {/*<SectorSubBarChart pfRow={pfRow} bmRow={bmRow} data={data} subTheme={subTheme} sector={sector} dataScale={dataScale}*/}
                    {/*                   changeDataScale={changeDataScale}*/}
                    {/*                   pfName={pfName} bmName={bmName} date={date} maxDate={maxDate} isReview={isReview}/>*/}

                </div>
                <div className={'canopia2 detail-sub-title'} style={{marginBottom: '20px'}}>
                    {subThemeValue === TOTAL ? 'Total ' + subTheme.label :
                        subThemeValue} exposure history
                </div>
                <DetailHistoChart pfRow={pfRow} bmRow={bmRow} data={data} subTheme={subTheme}
                                  subThemeValue={subThemeValue}
                                  pfName={pfName} bmName={bmName} date={date} maxDate={maxDate} isReview={isReview}/>
            </>;
            break;
        case 'topPos':
            theChart = <TopPosition pfRow={pfRow} bmRow={bmRow}
                                    subTheme={subTheme} subThemeValue={subThemeValue}
                                    subThemeValueFunc={changeSubThemeValue}
                                    changePfKey={changePfKey}
                                    pfName={pfName} bmName={bmName} date={date}/>;
            break;
        default:
            console.log("unknown chart type " + chartType.key);
    }

    return <>
        {theChart}
        {/*{!isReview && <span className={'label-hover'} onClick={exportAsImage} style={{position: "absolute", top: 65, right: 30}}>*/}
        {/*    <IconContext.Provider value={{className: "react-icons canopia2", size: "1.4em"}}>*/}
        {/*        <AiOutlineDownload/>*/}
        {/*    </IconContext.Provider>*/}
        {/*</span>}*/}
    </>
}
