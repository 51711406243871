import {BM, CHART_DATA_SCALE, getAllPosImpacts, getRatingColors, PF, THEMES, TOTAL} from "../CanopiaUtils";
import * as am4core from "@amcharts/amcharts4/core";

export const addAmcharts4Licence = () => {
    am4core.addLicense("CH439954682");
}

export const createChartData = (pfRows, now) => {
    // Create the histo charts data
    // const chartThemes = [THEMES[1], THEMES[2], THEMES[3], THEMES[4]];
    const chartThemes = THEMES;
    // const chartThemes = [THEMES[3]]; // CO2 only

    // anchor data for the relative charts
    let anchorData = {};

    let chartData = {};

    function getRelative(value, chartKey, valueKey) {
        let norm = null;
        if (value) {
            const anchorValue = anchorData[chartKey][valueKey];
            if (anchorValue) {
                norm = 100 * value / anchorValue;
            } else {
                norm = 100;
                anchorData[chartKey][valueKey] = value;
            }
        }
        return norm;
    }

    function getDenorm(valueNorm, scaleFactor, chartKey, valueKey) {
        let denorm = null;
        if (valueNorm) {
            let anchorValue = anchorData[chartKey][valueKey];
            if (anchorValue) {
                denorm = valueNorm * anchorValue;
            } else {
                anchorValue = scaleFactor / 100;
                denorm = valueNorm * anchorValue;
                anchorData[chartKey][valueKey] = anchorValue;
            }
        }
        return denorm;
    }

    function addCO2Data(pfRow, chartField, coverageField, chartKey, now, dateObj) {
        let pfValueRepAbs, bmValueRepAbs;
        // let pfValueRev, bmValueRev;
        let pfCoverage, bmCoverage;

        pfValueRepAbs = pfRow.esgReport[chartField];
        // pfValueRev = pfRow.esgReport[chartField2];
        pfCoverage = pfRow.esgReport[coverageField];
        let bmRow = pfRow.benchmark;
        if (bmRow && bmRow.esgReport) {
            bmValueRepAbs = bmRow.esgReport[chartField];
            // bmValueRev = bmRow.esgReport[chartField2];
            bmCoverage = bmRow.esgReport[coverageField];
        }
        // if (pfValueRepAbs || pfValueRev || (pfRow.hidden && pfRow.esgReport.co2Target)) {
        if (pfValueRepAbs || bmValueRepAbs || (pfRow.hidden && pfRow.esgReport.co2Target)) {

            // Init
            if (!anchorData.hasOwnProperty(chartKey)) {
                anchorData[chartKey] = {
                    pfValueRep: null, bmValueRep: null, // pfValueRev: null,
                    // bmValueRev: null
                    co2TargetPf: null
                };
            }
            let pfValueRepRel = getRelative(pfValueRepAbs, chartKey, "pfValueRep");
            let bmValueRepRel = null;
            // if (pfValueRepRel) {
                bmValueRepRel = getRelative(bmValueRepAbs, chartKey, "bmValueRep");
            // }
            // let pfValueRevNorm = getRelative(pfValueRev, chartKey, "pfValueRev");
            // let bmValueRevNorm = null;
            // if (pfValueRevNorm) {
            //     bmValueRevNorm = getRelative(bmValueRev, chartKey, "bmValueRev");
            // }

            let co2TargetNorm = pfRow.esgReport.co2Target;
            let co2TargetPfNorm = getDenorm(co2TargetNorm, pfValueRepRel, chartKey, "co2TargetPf");
            let co2TargetPf = getDenorm(co2TargetNorm, pfValueRepAbs, chartKey, "co2TargetNorm");

            const themeData = {date: pfRow.dateFmt};
            themeData[PF + chartField] = pfValueRepAbs;
            themeData[BM + chartField] = bmValueRepAbs;
            themeData[PF + chartField + "_rel"] = pfValueRepRel;
            themeData[BM + chartField + "_rel"] = bmValueRepRel;
            if (co2TargetNorm) {
                themeData.co2TargetPf = co2TargetPf; // Absolute chart
                themeData.co2TargetNorm = co2TargetPfNorm; // Relative chart
            }
            // Coverage
            themeData[PF + coverageField] = pfCoverage;
            themeData[BM + coverageField] = bmCoverage;

            // Add the data
            chartData[chartKey].data.push(themeData);
        }
    }

    pfRows.slice().reverse().forEach(pfRow => {
        let dateSplit = pfRow.dateFmt.split('-');
        let dateObj = new Date(parseInt(dateSplit[0]), parseInt(dateSplit[1]) - 1, parseInt(dateSplit[2]));
        chartThemes.forEach(chartTheme => {
            const chartSubThemes = chartTheme.subThemes;
            chartSubThemes.forEach(chartSubTheme => {
                const chartKey = chartSubTheme.key;
                if (!chartSubTheme.hasOwnProperty('valueFields')) {
                    return;
                }

                if (!chartData.hasOwnProperty(chartKey)) {
                    chartData[chartKey] = {
                        data: [], config: {
                            colors: {}
                        }
                    };
                }

                const chartField1 = chartSubTheme.valueFields[0];

                switch (chartTheme.key) {
                    case 'review':
                        if (pfRow.hidden) {
                            return;
                        }
                        const pfValue = pfRow.esgReport[chartField1];
                        let revData = {date: pfRow.dateFmt};
                        const chartField2 = chartSubTheme.valueFields[1];
                        if (pfValue) {
                            const pfConsensusRank = pfRow.esgReport[chartField2];
                            revData[PF + chartField1] = pfValue < 0 ? 0 : pfValue;
                            // revData[PF + chartField2] = pfConsensusRank === pfValue || pfConsensusRank < 0 ? null : pfConsensusRank;
                            revData[PF + chartField2] = pfConsensusRank < 0 ? 0 : pfConsensusRank;
                            const colors = getRatingColors(pfValue);
                            revData[PF + 'color'] = colors.color;
                            revData[PF + 'fontColor'] = colors.fontColor;
                            revData[PF + 'coverage'] = pfRow.esgReport.coverage;
                            // if (!chartData[chartKey].config.colors.hasOwnProperty(chartField1)) {
                            //     chartData[chartKey].config.colors[item.description] = chartTheme.chartColors[item.label];
                            // }
                        }
                        let bmRow = pfRow.benchmark;
                        if (bmRow && bmRow.esgReport) {
                            const bmValue = bmRow.esgReport[chartField1];
                            if (bmValue) {
                                const bmConsensusRank = bmRow.esgReport[chartField2];
                                revData[BM + chartField1] = bmValue < 0 ? 0 : bmValue;
                                revData[BM + chartField2] = bmConsensusRank < 0 ? 0 : bmConsensusRank;
                                const bmColors = getRatingColors(bmValue);
                                revData[BM + 'color'] = bmColors.color;
                                revData[BM + 'fontColor'] = bmColors.fontColor;
                                revData[BM + 'coverage'] = bmRow.esgReport.coverage;
                            }
                        }
                        chartData[chartKey].data.push(revData);
                        break;
                    case 'co2':
                        addCO2Data(pfRow, chartField1, chartSubTheme.valueFields[2], chartKey, now, dateObj);
                        break;
                    case 'impacts':
                    case 'controversies':
                    case 'netZero':
                        if (pfRow.hidden) {
                            return;
                        }
                        let themeData = createESGMetricsChartData(chartField1, pfRow);

                        // Avoid data in the future...
                        if (now.getTime() > dateObj.getTime()) {
                            chartData[chartKey].data.push(themeData);
                        }

                        break;

                    default:
                        console.log("unsupported chart key " + chartTheme.key);
                }
            });
        });
    });

    return chartData;
}

export const createESGMetricsChartData = (themeField, pfRow) => {
    let themeData = {date: pfRow.dateFmt};

    const themes = themeField === 'posImpacts' ? getAllPosImpacts(pfRow.esgReport) : pfRow.esgReport[themeField];
    let pfTotal = 0, bmTotal = 0;
    if (themes && themes.length > 0) {
        themes.forEach(item => {
            themeData[PF + item.description] = item.weight;
            pfTotal += item.weight;
            // if (!chartData[chartKey].config.colors.hasOwnProperty(item.description)) {
            //     chartData[chartKey].config.colors[item.description] = chartTheme.chartColors[item.label];
            // }
        });
        themeData[PF + TOTAL] = pfTotal;
    } else {
        themeData[PF + TOTAL] = 0;
    }

    const bmRow = pfRow.benchmark;
    if (bmRow) {
        const themes = themeField === 'posImpacts' ? getAllPosImpacts(bmRow.esgReport) : bmRow.esgReport[themeField];
        if (themes && themes.length > 0) {
            themes.forEach(item => {
                themeData[BM + item.description] = item.weight;
                bmTotal += item.weight;
                // if (!chartData[chartKey].config.colors.hasOwnProperty(item.description)) {
                //     chartData[chartKey].config.colors[item.description] = chartTheme.chartColors[item.label];
                // }
            });
            themeData[BM + TOTAL] = bmTotal;
        }
    } else {
        themeData[BM + TOTAL] = 0;
    }

    return themeData;
}

// PDFs setup
export const averageMin = 0.45;
export const averageMax = 1.0;
export const strengthMin = -0.15;
export const strengthMax = 0.45;

function getConsensusData(pfRow, id, rescale, color) {
    if (!pfRow) {
        return null;
    }
    const esgReport = pfRow.esgReport;

    if (!esgReport || esgReport.coverage === 0) {
        return null;
    }

    const pfAverageRaw = esgReport.consensusWgt;
    const pfAverage = Math.max(Math.min(pfAverageRaw, averageMax), averageMin);
    const pfStrengthRaw = esgReport.strengthWgt;
    const pfStrength = Math.max(Math.min(pfStrengthRaw, strengthMax), strengthMin);

    const pfName = pfRow.category;
    let pfData = {};
    pfData["name"] = pfName;
    pfData["avg_raw"] = pfAverageRaw;
    pfData["avg"] = pfAverage;
    pfData["str_raw"] = pfStrengthRaw;
    pfData["str"] = pfStrength;
    pfData["wgt"] = rescale ? pfRow.relWgt * esgReport.coverage : esgReport.coverage;
    pfData["cons_rating"] = esgReport.consensusRating;
    pfData["cons_rating_rank"] = esgReport.consensusRatingRank;
    pfData["color"] = color;
    // pfData["font_color"] = color;
    pfData["font_color"] = '#212529';
    pfData["id"] = id;
    if (rescale) {
        pfData["isSub"] = true;
    }

    return pfData;
}

export const createGradeSubChartData = (pfRow, colors) => {
    let themeData = [];
    let id = 0;

    if (pfRow) {

        // Put the BM first as it will be added behind the PF by the chart lib in case they overlap
        const bmConsensusData = getConsensusData(pfRow.benchmark, id, false, '#a0a0a0');
        if (bmConsensusData) {
            themeData.push(bmConsensusData);
            id++;
        }

        const consensusData = getConsensusData(pfRow, id, false, colors[0]);
        if (consensusData) {
            themeData.push(consensusData);
            id++;
        }

        pfRow.subRows.forEach(subRow => {

            const consensusData = getConsensusData(subRow, id, true, colors[4]);
            if (consensusData) {
                themeData.push(consensusData);
                id++;
            }
        });
    }

    return themeData;
}

export const createESGMetricsSubChartData = (themeField, subField, pfRow, dataScale) => {
    let themeData = {date: pfRow.dateFmt};

    pfRow.subRows.forEach(subRow => {

        const themes = themeField === 'posImpacts' ? getAllPosImpacts(subRow.esgReport) : subRow.esgReport[themeField];
        let pfTotal = 0, bmTotal = 0;
        if (themes && themes.length > 0) {
            const pfName = subRow.category;
            themes.forEach(item => {
                const tgtWgt = dataScale.key === CHART_DATA_SCALE[0].key ? item.weight : subRow.relWgt * item.weight;
                if (subField === TOTAL) {
                    pfTotal += tgtWgt;
                } else if (subField === item.description) {
                    themeData[PF + pfName] = tgtWgt;
                }
            });
            if (subField === TOTAL) {
                themeData[PF + pfName] = pfTotal;
            }

            const bmRow = subRow.benchmark;
            if (bmRow) {
                const themes = themeField === 'posImpacts' ? getAllPosImpacts(bmRow.esgReport) : bmRow.esgReport[themeField];
                if (themes && themes.length > 0) {
                    themes.forEach(item => {
                        const tgtWgt = dataScale.key === CHART_DATA_SCALE[0].key ? item.weight : subRow.relWgt * item.weight;
                        if (subField === TOTAL) {
                            bmTotal += tgtWgt;
                        } else if (subField === item.description) {
                            themeData[BM + pfName] = tgtWgt;
                        }
                    });
                    if (subField === TOTAL) {
                        themeData[BM + pfName] = bmTotal;
                    }
                }
            }
        }
    });

    return themeData;
}

export const createCO2SubChartData = (themeField, subField, pfRow, dataScale) => {
    let themeData = {date: pfRow.dateFmt};

    const esgReport = pfRow.esgReport;
    const pfCoverage = esgReport ? esgReport.co2EmissionsCoverage : null;
    const bmRow = pfRow.benchmark;
    const bmCoverage = bmRow && bmRow.esgReport ? bmRow.esgReport.co2EmissionsCoverage : null;

    pfRow.subRows.forEach(subRow => {

        let pfValueRepAbs, bmValueRepAbs;
        // let pfValueRev, bmValueRev;

        pfValueRepAbs = subRow.esgReport[themeField];
        // pfValueRepAbs = dataScale.key === CHART_DATA_SCALE[0].key ? pfValueRepAbs : subRow.relWgt * pfValueRepAbs;
        pfValueRepAbs *= subRow.relWgt * subRow.esgReport.co2EmissionsCoverage / pfCoverage;
        // pfValueRev = pfRow.esgReport[chartField2];
        let bmRow = subRow.benchmark;
        if (bmRow && bmRow.esgReport) {
            const bmEsgReport = bmRow.esgReport;
            bmValueRepAbs = bmEsgReport[themeField];
            // bmValueRepAbs = dataScale.key === CHART_DATA_SCALE[0].key ? bmValueRepAbs : subRow.relWgt * bmValueRepAbs;
            bmValueRepAbs *= bmRow.relWgt * bmEsgReport.co2EmissionsCoverage / bmCoverage;
            // bmValueRev = bmRow.esgReport[chartField2];
        }
        // if (pfValueRepAbs || pfValueRev || (pfRow.hidden && pfRow.esgReport.co2Target)) {
        if (pfValueRepAbs || (subRow.hidden && subRow.esgReport.co2Target)) {

            const pfName = subRow.category;
            themeData[PF + pfName] = pfValueRepAbs;

            const bmRow = subRow.benchmark;
            if (bmRow) {
                themeData[BM + pfName] = bmValueRepAbs;
            }
        }
    });

    return themeData;
}

export const getChartData = (chartData, date) => {
    return chartData.length > 0 ? chartData.find(d => d.date === date) ? chartData.filter(d => d.date === date)[0] : {} : {};
}