import React, {useRef} from "react";
import {AMOUNT_CONTROLS, getAmountValueForFunds, THEMES, VIEW_MODES} from './CanopiaUtils';
import ESGMetricsValues from "./ESGMetricsValues";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {SecuredDownloadLink} from "./SecuredDownloadLink";
import {useSelector} from "react-redux";
import {selectPortfolioState} from "../../reducer/portfolioSlice";
import {selectClientState} from "../../reducer/clientSlice";
import NameTrunc from "./NameTrunc";

export default function FundRow(props) {

    const pfRow = props.row;
    const theme = props.theme;
    const subTheme = props.subTheme;

    const themes = THEMES;

    const {clientConfig} = useSelector(selectClientState);
    const {selectedAmountField} = useSelector(selectPortfolioState);

    const target = useRef(null);

    // const [catControlVisibility, setCatControlVisibility] = useState("hidden");
    //
    // const showCategoryControls = () => {
    //     setCatControlVisibility("visible");
    // }
    // const hideCategoryControls = () => {
    //     setCatControlVisibility("hidden");
    // }

    const name = pfRow.category;
    const nameTrunc = <NameTrunc name={name} size={35}/>;

    let downloadIcon = '';
    if (pfRow.esgReport.reportAvailable) {
        let prefix = pfRow.dateFmt + '_' + clientConfig.clientWrapper.name + '_ESG Check';
        downloadIcon =
            // <span style={{visibility: catControlVisibility, float: "right"}}>
            <span style={{float: "right"}}>
                <SecuredDownloadLink client={clientConfig.clientWrapper} date={pfRow.dateFmt} category={name} fileName={prefix + ' - ' + name} method='pdf'/>
            </span>
    }

    // let structure = pfRow.structure;
    // if (structure === "Single Layer") {
    //     structure = "SF";
    // } else if (structure === "Fund Of Funds") {
    //     structure = "FOF";
    // }
    const managerTrunc = <NameTrunc name={pfRow.manager} size={20}/>;

    const assetClassTrunc = <NameTrunc name={pfRow.lipperGlobalScheme} size={20}/>;

    let esgThemeValues;
    let bmName = '';
    let bmNameTrunc;
    let bmClassName = 'bm-display';
    let bmBgClass = 'background-bm-light';
    if (bmClassName === 'bm-display') {
        let bmRow = pfRow.benchmark;
        let bmEsgReport;
        let bmTrend;
        if (bmRow) {
            bmName = bmRow.category;
            bmNameTrunc = <NameTrunc name={bmName} size={30}/>;
            bmEsgReport = bmRow.esgReport;
            bmTrend = bmEsgReport.trend;
        }
        esgThemeValues = <ESGMetricsValues name={name}
                                           bmName={bmName}
                                           pfEsgReport={pfRow.esgReport}
                                           bmEsgReport={bmEsgReport}
                                           pfTrend={pfRow.esgReport.trend}
                                           bmTrend={bmTrend}
                                           bmClassName={bmClassName}
                                           theme={theme} themes={themes}
                                           subTheme={subTheme}
                                           viewModeKey={VIEW_MODES[1].key}
                                           borderLeftClassName={'canopia-border-left'}/>
    }

    let lookthroughFormatted = <span style={{whiteSpace: "nowrap"}}>{pfRow.lookthroughFormatted}</span>;
    let lookthrough;

    if (pfRow.lookthroughStatus && pfRow.lookthroughStatus !== 'ok') {

        lookthrough =
            <OverlayTrigger
                placement='auto'
                delay={{show: 500, hide: 0}}
                trigger={["hover", "focus"]}
                target={target}
                overlay={
                    <Tooltip id="tooltip-bottom">
                        {pfRow.lookthroughStatus === 'warning' ? 'Old lookthrough (> 3 months)' : 'Outdated lookthrough (> 6 months)'}
                    </Tooltip>
                }
                defaultShow={false}>
                {/*need to set display to prevent conflict between tr mouseover and tooltip*/}
                <span ref={target} style={{display: 'inline-block'}} className={'lookthrough-' + pfRow.lookthroughStatus}>
                    {lookthroughFormatted}
                </span>
            </OverlayTrigger>;
    } else {
        lookthrough = lookthroughFormatted
    }

    // Amount
    // Relative % is not relevant in Funds tab
    const adjAmountField = selectedAmountField.key === 'relPct' ? AMOUNT_CONTROLS[1] : selectedAmountField;
    let amountValue = getAmountValueForFunds(pfRow, adjAmountField);

    return <tr key={pfRow.isin}>
        {/*<td onMouseOver={showCategoryControls} onMouseOut={hideCategoryControls}>*/}
        <td colSpan={2}>
            {nameTrunc}
        </td>
        {/*<td onMouseOver={showCategoryControls} onMouseOut={hideCategoryControls}>{downloadIcon}</td>*/}
        <td>{downloadIcon}</td>
        {/*<td>{pfRow.isin}</td>*/}
        <td>{pfRow.currency}</td>
        {/*<td>{structure}</td>*/}
        <td>
            {assetClassTrunc}
        </td>
        {/*<td>{pfRow.account}</td>*/}
        <td>
            {managerTrunc}
        </td>
        {/*<td>{pfRow.source}</td>*/}
        <td>{lookthrough}</td>
        <td align="right">{pfRow.esgReport.nbPosFormatted}</td>
        {/*<td align="right">{pfRow.nbCov}</td>*/}
        <td align="right">{amountValue}</td>
        {esgThemeValues}
        <td className={bmBgClass + " canopia-border-left"}>
            {bmNameTrunc}
        </td>
    </tr>
}
