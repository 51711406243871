import React, {useCallback, useLayoutEffect, useRef} from "react";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4charts from "@amcharts/amcharts4/charts";
import {AiOutlineDownload} from "react-icons/ai";
import {IconContext} from "react-icons";
import {BM, getGradeFromRank, PF, THEMES} from "../CanopiaUtils";
import {renderToStaticMarkup} from "react-dom/server";
import ConsensusGrade from "../ConsensusGrade";
import FinalGrade from "../FinalGrade";
import {addAmcharts4Licence} from "./ChartUtils";
import DetailHistoCoverageChart from "./DetailHistoCoverageChart";

export default function DetailHistoGradeChart(props) {

    // const dataType = props.dataType;
    // const theme = props.theme;
    const subTheme = props.subTheme;
    const pfName = props.pfName;
    const bmName = props.bmName;
    const maxDate = props.maxDate;

    const data = props.data;
    const chartData = data.data;
    const isReview = props.isReview;
    // const chartConfig = data.config;

    const chartDiv = "chart_histo_grade_div";

    const tgtChart = useRef(null);

    const createChartInternal = useCallback(isReview => {
        addAmcharts4Licence();
        am4core.options.autoDispose = true;
        let chart = am4core.create(chartDiv, am4charts.XYChart);
        chart.marginTop = 30;
        chart.marginBottom = 30;
        chart.data = chartData;
        // Set input format for the dates
        chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";

        if (isReview) {
            chart.exporting.menu = new am4core.ExportMenu();
            chart.exporting.filePrefix = pfName + "_" + subTheme.labelForExport;
        } else {
            let options = chart.exporting.getFormatOptions("svg");
            options.quality = 1;
            chart.exporting.setFormatOptions("svg", options);
        }

        // Cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = 'zoomX';
        chart.cursor.lineX = null;
        chart.cursor.lineY = null;

        chart.legend = new am4charts.Legend();
        let legend = chart.legend;
        legend.reverseOrder = true;
        let markerTemplate = legend.markers.template;
        markerTemplate.width = 40;
        markerTemplate.height = 40;

        // Axis
        let xAxis = chart.xAxes.push(new am4charts.DateAxis());
        xAxis.cursorTooltipEnabled = true;
        xAxis.tooltip.background.fill = am4core.color("#1D416D");
        xAxis.tooltipDateFormat = "yyyy-MM-dd";
        xAxis.baseInterval = {timeUnit: "day", count: 1};
        // xAxis.renderer.labels.template.location = 0.0001;
        // xAxis.extraMax = 0.1; // Give some margin between the seriesConfig and the right part of the chart box
        xAxis.extraMin = 0.05; // Give some margin for the 1st grade bullet
        if (maxDate) {
            xAxis.max = maxDate;
        }
        xAxis.gridIntervals.setAll([
            {timeUnit: "year", count: 1},
            {timeUnit: "year", count: 2},
            {timeUnit: "year", count: 5},
            {timeUnit: "year", count: 10},
            {timeUnit: "year", count: 50},
            {timeUnit: "year", count: 100}
        ]);
        xAxis.renderer.grid.template.disabled = false;
        // xAxis.renderer.cellStartLocation = 0;

        xAxis.renderer.ticks.template.disabled = false;
        xAxis.renderer.ticks.template.strokeOpacity = 1;
        xAxis.renderer.ticks.template.stroke = am4core.color("#bbbbbb");
        xAxis.renderer.ticks.template.strokeWidth = 1;
        xAxis.renderer.ticks.template.length = 5;
        xAxis.renderer.line.strokeOpacity = 1;
        xAxis.renderer.line.strokeWidth = 1;
        xAxis.renderer.line.stroke = am4core.color("#bbbbbb");

        let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
        // yAxis.title.text = yAxisName;
        // yAxis.dataFields.category = 'rating';
        yAxis.cursorTooltipEnabled = false;
        // yAxis.numberFormatter.numberFormat = "#%"; // "#'###" single quote not supported
        // yAxis.extraMax = 0.1; // Give some margin between the seriesConfig and the top of the chart box
        // yAxis.extraMin = 0.1; // Give some margin between the seriesConfig and the bottom of the chart box
        yAxis.min = 1;
        yAxis.max = 12;
        yAxis.renderer.minGridDistance = 1;
        yAxis.renderer.grid.template.strokeOpacity = 0;

        // LABELS DISABLED
        yAxis.renderer.labels.template.disabled = true;
        // This Adapter is to change the text in the valueAxis Tooltip
        const yAxisValues = {
            // 0: '',
            1: '',
            2: 'D',
            3: 'C-',
            4: 'C',
            5: 'C+',
            6: 'B-',
            7: 'B',
            8: 'B+',
            9: 'A-',
            10: 'A',
            11: 'A+',
            12: '' // added to give some margin to display the A+
        };
        Object.entries(yAxisValues).forEach(([value, label]) => {
            let range = yAxis.axisRanges.create();
            range.value = value;
            // range.grid.strokeOpacity = 0;
            // range.label.text = label;
            range.label.html = renderToStaticMarkup(
                <FinalGrade rating={label} rank={value} size={30}/>
            );
            range.label.location = 1;
            range.label.fillOpacity = 1;
        });

        // start with the BM to have the PF over the BM. this requires the legend to be in the reversed order
        const seriesConfig = [];
        if (bmName) {
            seriesConfig.push({
                prefix: BM,
                name: bmName,
                color: "#a0a0a0",
                bulletSize: 6,
                bulletOpacity: 0.8,
                offset: 0
            });
        }
        seriesConfig.push({
            prefix: PF,
            name: pfName,
            color: "#1D416D",
            bulletSize: 6,
            bulletOpacity: 0.8,
            offset: 0
        });

        // only way to get the right colors in the legend
        chart.colors.list = [
            // am4core.color("#1D416D"),
            // am4core.color("#a0a0a0"),
            am4core.color("#fff"),
            am4core.color("#fff")
        ];

        const dateField = "date";
        const categoryField = subTheme.valueFields[0];
        const categoryField2 = subTheme.valueFields[1];
        // Fill in the blanks to avoid uncontrolled behaviour
        chartData.forEach(dataPoint => {
            seriesConfig.forEach(config => {
                if (!dataPoint.hasOwnProperty(config.prefix + categoryField) || dataPoint[config.prefix + categoryField] < 1) {
                    dataPoint[config.prefix + categoryField] = null;
                }
            });
        });

        seriesConfig.forEach(config => {
            let series = chart.series.push(new am4charts.LineSeries());
            series.name = config.name;
            series.dataFields.valueY = config.prefix + categoryField;
            series.dataFields.value = config.prefix + categoryField2;
            series.dataFields.dateX = dateField;
            series.stroke = am4core.color(config.color);
            series.strokeWidth = 2;
            series.strokeOpacity = 0.5;
            series.yAxis = yAxis;

            // Tooltip
            series.tooltipHTML = "<div style='max-width: 150px; text-wrap: wrap'><b>{config.name}</b></div>"; // Must provide something to enable it even if there is the adapter below
            series.tooltip.getFillFromObject = false;
            series.tooltip.background.stroke = series.stroke;
            series.tooltip.background.strokeWidth = 1;
            series.tooltip.background.strokeOpacity = 0.85;
            // series.tooltip.background.fill = series.stroke;
            series.tooltip.background.fill = am4core.color('#ffffff');
            // series.tooltip.label.fill = am4core.color('#ffffff');
            // series.tooltip.label.fill = series.stroke;
            series.tooltip.label.fill = am4core.color('#202020');
            // Change tooltip text (rank -> grade)
            series.tooltip.label.adapter.add("htmlOutput", (text, target, key) => {
                if (target.dataItem) {
                    const values = target.dataItem.values;
                    const value = values.valueY.value;
                    const value2 = values.value.value;
                    const consensusGrade = getGradeFromRank(value2);
                    // return config.name + "\nFinal Grade: " + getGradeFromRank(value) + "\nConsensus Grade: " + (consensusGrade ? consensusGrade : '-');
                    return "<div style='max-width: 150px; text-wrap: wrap'><b>" + config.name + "</b></div>" +
                        "<table><tr>" +
                        "<td>Final Grade</td>" +
                        "<td style='padding-left: 5px; text-align: right'>" + renderToStaticMarkup(
                            <FinalGrade rating={getGradeFromRank(value)} rank={value} size={30}/>
                        ) + "</td>" +
                        "</tr><tr>" +
                        "<td>Consensus Grade</td>" +
                        "<td style='padding-left: 5px; text-align: right'>" + (consensusGrade ? renderToStaticMarkup(
                            <ConsensusGrade rating={consensusGrade} rank={value2}/>
                        ) : '-') + "</td>" +
                        "</tr>" +
                        "</table>";
                }
                return '';
            });

            let circleBullet = series.bullets.push(new am4charts.CircleBullet());
            circleBullet.circle.radius = config.bulletSize;
            circleBullet.circle.fill = am4core.color(series.stroke);
            circleBullet.circle.fillOpacity = config.bulletOpacity;
            circleBullet.circle.stroke = series.stroke;
            circleBullet.circle.strokeWidth = 2;
            circleBullet.circle.strokeOpacity = 1;
            circleBullet.circle.y = config.offset;
            // circleBullet.circle.propertyFields.fill = config.prefix + "color";
            // circleBullet.circle.propertyFields.stroke = config.prefix + "color";
            // circleBullet.circle.propertyFields.fill = series.stroke;

            // Makes the circleBullet bigger on mouse over
            let state = circleBullet.states.create("hover");
            state.properties.scale = 1.2;

            // let labelBullet = series.bullets.push(new am4charts.LabelBullet());
            // labelBullet.label.text = "{valueY.value}";
            // labelBullet.label.fontSize = 8;
            // // labelBullet.label.fontWeight = "bold";
            // labelBullet.label.dy = 1;
            // // labelBullet.label.fill = am4core.color("#fff");
            // // labelBullet.label.fill = am4core.color("#202020");
            // // labelBullet.label.propertyFields.fill = config.prefix + "fontColor";
            // labelBullet.label.propertyFields.fill = series.stroke;
            // labelBullet.label.adapter.add("text", function (text, target, key) {
            //     if (target.dataItem) {
            //         const value = target.dataItem.values.valueY.value;
            //         return getGradeFromRank(value);
            //     }
            //     return '';
            // });

            let consensusLabel = series.bullets.push(new am4charts.LabelBullet());
            consensusLabel.label.text = "{value}";
            consensusLabel.label.horizontalCenter = "left";
            consensusLabel.label.dx = 7;
            consensusLabel.label.dy = config.offset - 3;
            // consensusLabel.label.dx = 5;
            // consensusLabel.label.dy = -5;
            consensusLabel.label.adapter.add("text", function (text, target, key) {
                if (target.dataItem) {
                    const value = target.dataItem.values.valueY.value;
                    const value2 = target.dataItem.values.value.value;
                    // const grade = getGradeFromRank(value2);
                    return value && value2 && value !== value2 ? '*' : null;
                    // return grade ? '*' : null;
                }
                return '';
            });
        });

        // // Consensus grade, if different from the final one
        // seriesConfig.forEach(config => {
        //
        //     let series = chart.series.push(new am4charts.LineSeries());
        //     series.name = config.name + " Consensus";
        //     series.dataFields.valueY = config.prefix + categoryField2;
        //     series.dataFields.dateX = dateField;
        //     series.stroke = am4core.color(config.color);
        //     series.strokeWidth = 0;
        //
        //     let circleBullet = series.bullets.push(new am4charts.CircleBullet());
        //     circleBullet.circle.radius = 12;
        //     circleBullet.circle.strokeWidth = 2;
        //     circleBullet.circle.fill = am4core.color('#fff');
        //     // circleBullet.circle.propertyFields.stroke = config.prefix + "color";
        //     circleBullet.circle.stroke = series.stroke;
        //
        //     // Makes the circleBullet bigger on mouse over
        //     let state = circleBullet.states.create("hover");
        //     state.properties.scale = 1.2;
        //
        //     let labelBullet = series.bullets.push(new am4charts.LabelBullet());
        //     labelBullet.label.text = "{valueY.value}";
        //     labelBullet.label.fontSize = 8;
        //     // labelBullet.label.fontWeight = "bold";
        //     labelBullet.label.dy = 1;
        //     // labelBullet.label.fill = am4core.color("#fff");
        //     // labelBullet.label.fill = am4core.color("#202020");
        //     // labelBullet.label.propertyFields.fill = config.prefix + "fontColor";
        //     labelBullet.label.propertyFields.fill = config.prefix + "color";
        //     labelBullet.label.adapter.add("text", function (text, target, key) {
        //         if (target.dataItem) {
        //             const value = target.dataItem.values.valueY.value;
        //             return getGradeFromRank(value);
        //         }
        //         return '';
        //     });
        // });

        tgtChart.current = chart;

    }, [chartData, chartDiv, pfName, bmName, maxDate, subTheme]);

    useLayoutEffect(() => {
        am4core.useTheme(am4themes_animated);

        createChartInternal(isReview);
    }, [createChartInternal, isReview]);

    const exportAsImage = () => {
        if (tgtChart) {
            tgtChart.current.exporting.filePrefix = pfName + "_" + subTheme.labelForExport;
            tgtChart.current.exporting.export("svg");
        }
    }

    return <>
        <div style={{display: "grid", gridTemplateColumns: "1fr 30px"}}>
            <p className={'canopia2'} style={{margin: '0 0 10px 10px'}}>
            </p>
            {!isReview && <span className={'label-hover'} onClick={exportAsImage}>
                <IconContext.Provider value={{className: "react-icons canopia2", size: "1.4em"}}>
                    <AiOutlineDownload/>
                </IconContext.Provider>
            </span>}
        </div>
        <div id={chartDiv} className="chart-carbon-trajectory"/>
        {isReview && <DetailHistoCoverageChart data={data} pfName={pfName} bmName={bmName} subTheme={THEMES[0].subThemes[2]}
                                               categoryField={THEMES[0].subThemes[2].valueFields[0]}
                                               maxDate={maxDate} isReview={isReview}/>}
    </>
}
