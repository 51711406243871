import React from "react";
import {useSelector} from 'react-redux';
import {selectPortfolioState} from "../../reducer/portfolioSlice";
import Table from "react-bootstrap/Table";
import {SecuredDownloadLink} from "./SecuredDownloadLink";
import {selectClientState} from "../../reducer/clientSlice";

export default function Download(props) {

    const {clientConfig} = useSelector(selectClientState);
    const {pfData, view} = useSelector(selectPortfolioState);

    if (!pfData) {
        return <div>No data found for this client</div>;
    }

    const isSales = props.isSales;

    let pfView = view != null ? view : clientConfig.clientViews[0];

    let reports = [];
    const pfReports = pfData.pfReports[pfView.viewId];
    Object.entries(pfReports).forEach(([date, rootPf]) => {
        if (!rootPf.hidden) {
            reports.push(rootPf);
        }
    });

    let dnlHisto = [];
    let keyIndex = 0;
    const colorClass = 'canopia2';
    reports.forEach(pfRow => {

        let dateComp = <td>
            <div className={colorClass + ""}>
                <b>
                    <span style={{whiteSpace: "nowrap"}}>{pfRow.dateFmt}</span>
                </b>
            </div>
        </td>

        let prefix = pfRow.dateFmt + '_' + clientConfig.clientWrapper.name;

        let fileKeys = pfRow.fileKeys;

        let link1 = '';
        if (fileKeys && fileKeys.hasOwnProperty('XL')) {
            link1 = <SecuredDownloadLink client={clientConfig.clientWrapper} date={pfRow.dateFmt} fileName={prefix + '_ESG_Portfolio_Report'} method='excel'/>
        }

        let link2 = '';
        if (fileKeys && fileKeys.hasOwnProperty('GLOBAL')) {
            link2 = <SecuredDownloadLink client={clientConfig.clientWrapper} date={pfRow.dateFmt} category='GLOBAL_REPORT' fileName={prefix + '_ESG_Global_Report'} method='pdf'/>;
        }

        let link3 = '';
        if (fileKeys && fileKeys.hasOwnProperty('ARCHIVE')) {
            link3 = <>
                <SecuredDownloadLink client={clientConfig.clientWrapper} date={pfRow.dateFmt} fileName={prefix + '_ESG_Reports'} method='archive'/> ({fileKeys['ARCHIVE']})
            </>;
        }

        let link7 = '';
        if (fileKeys && fileKeys.hasOwnProperty('SCS')) {
            link7 = <SecuredDownloadLink client={clientConfig.clientWrapper} date={pfRow.dateFmt} category='SCS' fileName={prefix + '_Swiss_Climate_Scores_Report'} method='pdf'/>;
        }

        let link4 = '';
        if (fileKeys && fileKeys.hasOwnProperty('PACTA')) {
            link4 = <SecuredDownloadLink client={clientConfig.clientWrapper} date={pfRow.dateFmt} category='PACTA' fileName={prefix + '_PACTA_Report'} method='pdf'/>;
        }

        let link5 = '';
        if ((isSales || clientConfig.showIC) && fileKeys && fileKeys.hasOwnProperty('IC')) {
            link5 = <>IC<SecuredDownloadLink client={clientConfig.clientWrapper} date={pfRow.dateFmt} category='IC-FC' fileName={prefix + '_IC-FC_Report'} method='pdf'/></>;
        }

        let link6 = '';
        if ((isSales || clientConfig.showIC) && fileKeys && fileKeys.hasOwnProperty('IO')) {
            link6 = <>IO<SecuredDownloadLink client={clientConfig.clientWrapper} date={pfRow.dateFmt} category='IO-FC' fileName={prefix + '_IO-FC_Report'} method='pdf'/></>;
        }

        dnlHisto.push(
            <tr key={"dnl" + keyIndex}>
                {dateComp}
                <td className={'canopia-border-left'}>
                    {link1}
                </td>
                <td className={'canopia-border-left'}>
                    {link2}
                </td>
                <td className={'canopia-border-left'}>
                    {link3}
                </td>
                <td className={'canopia-border-left'}>
                    {link7}
                </td>
                <td className={'canopia-border-left'}>
                    {link4}
                </td>
                {(isSales || clientConfig.showIC) && <>
                    <td className={'canopia-border-left'}>
                        {link5}
                    </td>
                    <td className={'canopia-border-left'}>
                        {link6}
                    </td>
                </>}
                <td className={'canopia-border-left'} width={'70%'}/>
            </tr>
        );
        keyIndex++;
    });

    return (
        <div>
            <Table hover size="sm" className="portfolio sticky-thc">
                <thead>
                <tr key="header1">
                    <th className={colorClass + " background-light"}><span style={{whiteSpace: "nowrap"}}>Holdings</span></th>
                    <th className={colorClass + " background-light canopia-border-left"}><span style={{whiteSpace: "nowrap"}}>Excel Report</span></th>
                    <th className={colorClass + " background-light canopia-border-left"}><span style={{whiteSpace: "nowrap"}}>Global Report</span></th>
                    <th className={colorClass + " background-light canopia-border-left"}><span style={{whiteSpace: "nowrap"}}>ESG Checks (zip)</span></th>
                    <th className={colorClass + " background-light canopia-border-left"}><span style={{whiteSpace: "nowrap"}}>Swiss Climate Scores</span></th>
                    <th className={colorClass + " background-light canopia-border-left"}><span style={{whiteSpace: "nowrap"}}>PACTA</span></th>
                    {(isSales || clientConfig.showIC) && <th className={colorClass + " background-light canopia-border-left"} colSpan={2}><span style={{whiteSpace: "nowrap"}}>Investment Controlling</span></th>}
                    <th className={colorClass + " background-light canopia-border-left"}/>
                </tr>
                </thead>
                <tbody>

                {dnlHisto.map(data => {
                    return data
                })}
                </tbody>
            </Table>
        </div>
    );
}
