import React from "react";
import TrendIcon from "./TrendIcon";
import {ProgressBar} from "react-bootstrap";
import CO2Icon from "./CO2Icon";
import {HiLightBulb} from "react-icons/hi";
import {Asterisk, Plus} from "react-bootstrap-icons";

export const CONTEXT_URL = window.location.protocol + "//" + process.env.REACT_APP_HOSTNAME + "/canopia/";
export const TIMEOUT = 36000000;
export const HIDE = "hide";
export const SHOW = "show";
export const ICON_SIZE = 26;
// Number of dates shown in the histo
export const HISTO_SIZE = 4;
export const EMPTY_DATE = "empty_date";
export const CO2 = <>CO<sub>2</sub></>;

export const OPTIONAL = '(optional)';
export const REQUIRED = '(required)';
export const RESET_ACTION = {
    type: "RESET"
}

export const PF = "pf_";
export const BM = "bm_";
export const PF_KEY_SEPARATOR = "#@#";

export const VIEW_MODES = [
    {key: 'pf', label: 'Portfolio'},
    {key: 'bm', label: 'Benchmark'},
    {key: 'hi', label: 'History'},
    {key: 'su', label: 'Suitability'}
];

export const BM_CONTROL = [
    {key: 'pf', label: 'Portfolio'},
    {key: 'bm', label: 'Benchmark'},
];

export const PF_MODE = 'pf_mode';
export const SA_MODE = 'sa_mode';

export const NO_FILL = 'no_fill';
export const TOTAL = 'Total';

export const CHART_TYPES = [
    {key: 'detail', label: 'Details'},
    // {key: 'detail2', label: 'Detail 2'},
    // {key: 'histo', label: 'Histo'},
    {key: 'topPos', label: 'Top positions'}
];
export const CHART_DATA_SCALE = [
    {key: 'raw', label: 'Raw'},
    {key: 'res', label: 'Rescaled'}
];
export const THEMES = [
    {
        key: 'review', label: 'ESG Review', cols: 3, mainViewShow: true, bmViewShow: true, showProp: null,
        subThemes: [
            {
                key: 'grade', label: 'Final Grade', labelForExport: 'Final Grade', mainViewShow: true, cols: 1,
                valueFields: ['ratingRank', 'consensusRatingRank'],
                colors: [
                    // '#adad2f',
                    '#1D416D',
                    '#285D85',
                    '#33789D',
                    '#3D94B6ff',
                    '#48AFCEff',
                    '#53CBE6ff'
                ]
            },
            {
                key: 'trend', label: 'Trend', mainViewShow: true, cols: 1
            },
            {
                key: 'coverage', label: 'Coverage', labelForExport: 'Coverage', mainViewShow: true, cols: 1,
                valueFields: ['coverage'],
                formatAxis: "#%",
                formatValue: "#.00%"
            }
        ],
        subThemesHistoView: 'allFields',
        chartColors: {
            'ALC': '#ef838b',
            'G': '#ef838b',
            'GMO': '#ef838b',
            'N': '#ef838b',
            'P': '#ef838b',
            'T': '#ef838b',
            'W': '#ef838b',
            'ESG_BE': '#ef838b',
            'ESG_COR': '#ef838b',
            'ESG_ENV': '#ef838b',
            'ESG_HR': '#ef838b',
            'ESG_OR': '#ef838b'
        }
    },
    {
        key: 'controversies', label: 'Controversies', cols: 2, mainViewShow: true, bmViewShow: true, showProp: null,
        subThemes: [
            {
                key: 'sectors',
                label: 'Sensitive Sectors',
                labelForExport: 'Sensitive Sectors',
                mainViewShow: true,
                cols: 1, //
                valueFields: ['sectors'],
                colors: [
                    '#CF152D',
                    '#D63649',
                    '#DD5766',
                    '#E37982',
                    '#EA9A9F',
                    '#F1BBBB'
                ],
                formatAxis: "#%",
                formatValue: "#.00%"
            },
            {
                key: 'norms',
                label: 'Breach to Intl Norms',
                labelForExport: 'Breach to Intl Norms',
                mainViewShow: true,
                cols: 1, //
                valueFields: ['norms'],
                colors: [
                    '#CF152D',
                    '#D63649',
                    '#DD5766',
                    '#E37982',
                    '#EA9A9F',
                    '#F1BBBB'
                ],
                formatAxis: "#%",
                formatValue: "#.00%"
            }
        ],
        subThemesHistoView: 'singleField',
        chartColors: {
            'ALC': '#eccd93',
            'G': '#e9c17b',
            'GMO': '#e7b563',
            'N': '#e4a84c',
            'P': '#efd9ac',
            'T': '#e29a33',
            'W': '#e08c13',
            'ESG_BE': '#ffaaa9',
            'ESG_COR': '#ff9694',
            'ESG_ENV': '#ff8180',
            'ESG_HR': '#ff6b6b',
            'ESG_OR': '#ff5257',
            'ESG_CB': '#FC3342'
        }
    },
    {
        key: 'impacts', label: 'Impacts', cols: 2, mainViewShow: true, bmViewShow: true, showProp: null,
        subThemes: [
            {
                key: 'climatePos', label: 'Positive', labelForExport: 'Positive Impacts', mainViewShow: true, cols: 1, //
                valueFields: ['posImpacts'],
                colors: [
                    '#87c940',
                    '#98CF56',
                    '#A8D56C',
                    '#B9DC82',
                    '#C9E298',
                    '#DAE8AE'
                ],
                formatAxis: "#%",
                formatValue: "#.00%"
            }, // TODO add thematicBonds & highImpacts
            {
                key: 'climateNeg', label: 'Negative', labelForExport: 'Negative Impacts', mainViewShow: true, cols: 1, //
                valueFields: ['negImpacts'],
                colors: [
                    '#CF152D',
                    '#D63649',
                    '#DD5766',
                    '#E37982',
                    '#EA9A9F',
                    '#F1BBBB'
                ],
                formatAxis: "#%",
                formatValue: "#.00%"
            }
            // {key: 'thematicBonds', label: 'Thematic Bds', mainViewShow: true, cols: 1}
        ],
        subThemesHistoView: 'singleField',
        chartColors: {
            'CT': {main: '#768FD9', fill: '#768FD9'},
            'WT': {main: '#38ABD4', fill: '#38ABD4'},
            'GB': {main: '#3AAE2A', fill: '#3AAE2A'},
            'SB': {main: '#9be31c', fill: '#9be31c'},
            'FF': {main: '#E8C116', fill: '#E8C116'},
            'FFT': {main: '#E8C116', fill: '#f0eab4'},
            'FC': {main: '#66676c', fill: '#66676c'},
            'FCT': {main: '#66676c', fill: '#c2c8c6'},
            'HIF': {main: '#C677C1', fill: '#C677C1'}
        }
    },
    {
        key: 'co2', label: CO2, cols: 2, mainViewShow: true, bmViewShow: true, showProp: 'showCo2Data',
        subThemes: [
            {
                key: 'co2Intensity',
                label: <>{CO2} Intensity</>,
                labelForExport: 'CO2 Intensity',
                units: <>{CO2} / Revenues</>,
                unitsRaw: <>t {CO2} / mio $</>,
                mainViewShow: true,
                cols: 1, //
                // valueFields: ['co2IntensityNorm', 'co2IntensityNormFmt', 'co2IntensityCoverage', 'co2IntensityCoverageFmt', 'co2IntensityMax', 'co2IntensityNormReview']
                valueFields: ['co2IntensityNorm', 'co2IntensityNormFmt', 'co2IntensityCoverage', 'co2IntensityCoverageFmt', 'co2IntensityMax'],
                colors: [
                    '#1D416D',
                    '#285D85',
                    '#33789D',
                    '#3D94B6ff',
                    '#48AFCEff',
                    '#53CBE6ff'
                ],
                formatAxis: "#",
                formatValue: "#.00"
            },
            {
                key: 'co2Emissions',
                label: <>{CO2} Emissions</>,
                labelForExport: 'CO2 Emissions',
                units: <>kt {CO2}</>,
                unitsRaw: <>kt {CO2}</>,
                mainViewShow: false,
                cols: 1, //
                // valueFields: ['co2EmissionsNorm', 'co2EmissionsNormFmt', 'co2EmissionsCoverage', 'co2EmissionsCoverageFmt', 'co2EmissionsMax', 'co2EmissionsNormReview']
                valueFields: ['co2EmissionsNorm', 'co2EmissionsNormFmt', 'co2EmissionsCoverage', 'co2EmissionsCoverageFmt', 'co2EmissionsMax'],
                colors: [
                    '#1D416D',
                    '#285D85',
                    '#33789D',
                    '#3D94B6ff',
                    '#48AFCEff',
                    '#53CBE6ff'
                ],
                formatAxis: "#",
                formatValue: "#.00"
            }
        ],
        subThemesHistoView: 'singleField'
    },
    {
        key: 'netZero', label: 'Net-Zero', cols: 2, mainViewShow: false, bmViewShow: true, showProp: 'showNetZeroData',
        subThemes: [
            {
                key: 'aligned', label: 'Aligned',
                labelForExport: 'Net-zero Aligned', cols: 1, //
                valueFields: ['netZeroPos'],
                colors: [
                    '#59C386',
                    '#6ECC93',
                    '#83D4A0',
                    '#98DDAD',
                    '#ADE5BA',
                    '#C2EEC7'
                ],
                formatAxis: "#%",
                formatValue: "#.00%"
            },
            {
                key: 'misaligned', label: 'Misaligned',
                labelForExport: 'Net-zero Misaligned', cols: 1, //
                valueFields: ['netZeroNeg'],
                colors: [
                    '#CF152D',
                    '#D63649',
                    '#DD5766',
                    '#E37982',
                    '#EA9A9F',
                    '#F1BBBB'
                ],
                formatAxis: "#%",
                formatValue: "#.00%"
            }
        ],
        subThemesHistoView: 'singleField',
        chartColors: {
            'T1_5': {main: '#27C269', fill: '#27C269'},
            'TB2': {main: '#62cf86', fill: '#62cf86'},
            'T2': {main: '#8cdba3', fill: '#8cdba3'},
            'C': {main: '#b1e6bf', fill: '#b1e6bf'},
            'P_A': {main: '#f9b5b4', fill: '#f9b5b4'},
            'N_AL': {main: '#ea7170', fill: '#ea7170'},
            'N_D': {main: '#cf152d', fill: '#cf152d'},
            'NI': {main: '#a0a0a0', fill: '#a0a0a0'},
            'NA': {main: '#a0a0a0', fill: '#a0a0a0'}
        }
    }
];

export const CONTROV_CHART_COLORS = [
    '#1d416d'
    , '#2f537c'
    , '#41658c'
    , '#54789b'
    , '#678bab'
    , '#7c9fbb'
    , '#91b2cb'
    , '#a7c6db'
    , '#bddbeb'
    , '#d5effc'
];

export const REAL_ESTATE_THEMES = [
    {
        key: 'indicators', label: 'Ratings', cols: 6
    }
    , {
        key: 'kpi', label: 'KPI', cols: 8
    }
    , {
        key: 'characteristics', label: 'Characteristics', cols: 5
    }
];

const trends = [
    [2, 'The final grade went up by 2 or more levels'],
    [1, 'The final grade went up by 1 level'],
    [0, 'No change or no previous data'],
    [-1, 'The final grade went down by 1 level'],
    [-2, 'The final grade went down by 2 or more levels']
];

const co2VsBm = [
    [-0.7, '-50%'],
    [-0.4, '-30%'],
    [0, 'Neutral'],
    [0.4, '+30%'],
    [0.7, '+50%']
];

export const AMOUNT_CONTROLS = [
    {key: 'amount', label: 'Ccy'}, // The label must be replaced by the client currency
    {key: 'absPct', label: '%'},
    {key: 'relPct', label: <>%<sub>rel</sub></>},
];
export const PERIODICITY_VALUES = [
    {key: 'annual', label: 'Annual', months: 12},
    {key: 'semiAnnual', label: 'Semi-annual', months: 6},
    {key: 'quarterly', label: 'Quarterly', months: 3},
    {key: 'none', label: 'None', months: 1} // => show all months
];
export const ALL_CATEGORY_LEVELS = {key: 'all', label: 'All'};

// export const CO2_CHART_DATA_TYPES = ['reported', 'revised'];

export const CHART_SCALES = [
    {key: 'abs', label: 'Absolute'},
    {key: 'rel', label: 'Relative'}
];

export const getIconPath = (key, status) => {
    const icon = getIcon(key, status);
    if (icon) {
        return "/icons/" + icon + ".png";
    }
    return null;
}

export const getThemes = (clientConfig) => {
    let authThemes = [];

    THEMES.forEach(theme => {
        if (theme.showProp) {
            if (clientConfig[theme.showProp] === true) {
                authThemes.push(theme);
            }
        } else {
            authThemes.push(theme);
        }
    });

    return authThemes;
}

export const getAllAmountControls = (showAmounts, currency) => {
    return getAmountControls(AMOUNT_CONTROLS, showAmounts, currency);
}

const getAmountControls = (allControls, showAmounts, currency) => {
    let amountControls = [];

    allControls.forEach(amountControl => {
        if (amountControl.key === 'amount') {
            if (showAmounts === true) {
                // Replace the label by the client currency
                amountControls.push({key: 'amount', label: currency});
            }
        } else {
            amountControls.push(amountControl);
        }
    });

    return amountControls;
}

export const getAmountControlsForFunds = (showAmounts, currency) => {
    // Rel pct makes no sense
    let amountControls = [AMOUNT_CONTROLS[0], AMOUNT_CONTROLS[1]];
    return getAmountControls(amountControls, showAmounts, currency);
}

export const getIcon = (key, status) => {
    // let variant = '';
    // let variant = '_nofill';
    let variant = '_nofill_80';

    if (status === 'bad') {
        variant = '';
    }

    switch (key) {
        // breach
        case "BE":
        case "Business ethics":
            return "breach/Business_ethics_" + status + variant;
        case "CR":
        case "Corruption":
            return "breach/Corruption_" + status + variant;
        case "EN":
        case "Environment":
            return "breach/Environnement_" + status + variant;
        case "HR":
        case "Human rights":
            return "breach/Human_rights_" + status + variant;
        case "OR":
        case "Oppressive regime":
            return "breach/Oppressive_regim_" + status + variant;
        case "CB":
        case "Cluster bombs":
            return "breach/Cluster_bombs_" + status + variant;
        // sector
        case "ALC":
        case "Alcohol":
            return "sector/Alcohol_" + status + variant;
        case "GBL":
        case "Gambling":
            return "sector/Gambling_" + status + variant;
        case "GMO":
            return "sector/GMO_" + status + variant;
        case "NUC":
        case "Nuclear":
            return "sector/Nuclear_" + status + variant;
        case "PRN":
        case "Pornography":
            return "sector/Porno_" + status + variant;
        case "TOB":
        case "Tobacco":
            return "sector/Tobacco_" + status + variant;
        case "WPN":
        case "Weapon":
            return "sector/Weapon_" + status + variant;
        // impact
        case "CT":
        case "Clean Tech":
            return "impact/Clean_tech";
        case "WT":
        case "Water":
            return "impact/Water_theme";
        case "FF":
        case "Oil & Gas":
            return "impact/Fossil_fuel";
        case "FC":
        case "Coal":
            return "impact/Coal";
        case "FFT":
        case "Oil & Gas transition":
            return "impact/FossilTransition";
        case "FCT":
        case "Coal transition":
            return "impact/CoalTransition";
        case "HIF": // High impact funds
        case "High Impact Funds":
            return "impact/High_impact_funds";
        // bond
        case "GB":
        case "Green Bond":
            return "bond/Green_bonds";
        case "SB":
        case "Sustainable Bond":
            return "bond/Sustainability";
        default:
            return null;
    }
}

const header = <><p className={'canopia'}><b>CLIMATE IMPACT</b></p>
    <p>This section measures the sensitivity of the portfolio to climate change. The sum of positions’ weights with
        businesses exposed to fossil fuel (Negative impact) or participating to clean technologies (Positive impact).
        For the below mentioned categories, information is based on aggregated raw data and extracted from thematic
        funds (frequency analysis):</p></>;
const footer = <>
    <p>The scale of the gauge is between 0% and 20%:</p>
    <ul>
        <li>
            <span style={{float: 'left', marginLeft: '2px', marginTop: '6px', display: 'flex', width: 60}}>
                <div style={{width: 60}}>
                    <ProgressBar bsPrefix={"progress-canopia"} variant={"climate-pos"} now={0.12} max={0.2}/>
                </div>
            </span>
            Portfolio
        </li>
        <li>
            <span style={{float: 'left', marginLeft: '2px', marginTop: '6px', display: 'flex', width: 60}}>
                <div style={{width: 60}}>
                    <ProgressBar bsPrefix={"progress-canopia"} variant={"co2-bm"} now={0.09} max={0.2}/>
                </div>
            </span>
            Benchmark
        </li>
    </ul>
</>;

const co2IconsText = <>
    <p>The below indicator shows whether the portfolio exposure to {CO2} is significantly different from its
        benchmark:
    </p>
    <ul>
        {co2VsBm.map(row => {
            let relDiff = row[0];
            let trendIcon = <CO2Icon value={relDiff}/>;
            return <li key={'trend_' + relDiff}>{trendIcon} {row[1]}</li>
        })}
    </ul>
</>

const commonTextCO2 =
    <>
        <ul>
            <li>
                <span style={{float: 'left', marginLeft: '2px', marginTop: '6px', display: 'flex', width: 60}}>
                    <div style={{width: 60}}>
                        <ProgressBar bsPrefix={"progress-canopia"} variant={"co2"} now={80} max={120}/>
                    </div>
                </span>
                Portfolio
            </li>
            <li>
                <span style={{float: 'left', marginLeft: '2px', marginTop: '6px', display: 'flex', width: 60}}>
                    <div style={{width: 60}}>
                        <ProgressBar bsPrefix={"progress-canopia"} variant={"co2-bm"} now={100} max={120}/>
                    </div>
                </span>
                Benchmark
            </li>
        </ul>
        <p>
            The gauges are lightened when the coverage of the portfolio is lower than 50%.
        </p>
        <ul>
            <li>
                <span style={{float: 'left', marginLeft: '2px', marginTop: '6px', display: 'flex', width: 60}}>
                    <div style={{width: 60}}>
                        <ProgressBar bsPrefix={"progress-canopia"} variant={"co2-transparent"} now={80} max={120}/>
                    </div>
                </span>
                Portfolio
            </li>
            <li>
                <span style={{float: 'left', marginLeft: '2px', marginTop: '6px', display: 'flex', width: 60}}>
                    <div style={{width: 60}}>
                        <ProgressBar bsPrefix={"progress-canopia"} variant={"co2-bm-transparent"} now={100} max={120}/>
                    </div>
                </span>
                Benchmark
            </li>
        </ul>

        <p className={'canopia'}><b>RELATIVE DIFFERENCE TO BENCHMARK</b></p>
        {co2IconsText}
    </>
;

export const finalGradeText = <>
    <p className={'canopia'}><b>FINAL GRADE</b></p>
    <p>
        The final grade ranges from D to A+. It is determined based on the Consensus Grade and may be adjusted up or
        down by Controversies and Impacts (for more details, please refer to the methodology). If the final grade
        has been adjusted and is different from the Consensus grade, an icon <Asterisk size={'0.8em'}/> will be
        shown next to it.
    </p>
</>;

export const consensusGradeText = <>
    <p className={'canopia'}><b>CONSENSUS GRADE</b></p>
    <p>
        The Consensus Grade is determined by consolidating the individual ESG Consensus ratings for each position
        and evaluating their Average (sum of positions with Above ESG Consensus) and Strength (difference between
        the sum of Above Strong and Below Strong). The higher the average and strength, the better the Consensus
        grade. Icons used for the Consensus Grade are the same as those for the final grade but feature an empty
        background.
    </p>
</>

const covIndSize = '12px';
const subPfSpot = <div className={'consensus-icon-sub-pf'} style={{display: "inline-flex"}}/>;
export const LEGENDS = {
    // Dashboard
    "esgReport.ratingRank": <>
        {finalGradeText}
        {consensusGradeText}
    </>,
    "esgReport.trend": <>
        <p className={'canopia'}><b>TREND</b></p>
        <p>
            Evolution of the final grade since the last analysis.</p>
        <ul>
            {trends.map(trend => {
                let trendIcon = <TrendIcon value={trend[0]} show={true}/>;
                return <li key={'trend_' + trend[0]}>{trendIcon} {trend[1]}</li>
            })}
        </ul>
    </>,
    "esgReport.coverage": <>
        <p className={'canopia'}><b>COVERAGE</b></p>
        <p>
            Percentage of assets for which ESG Consensus rating (ex cash) is available. The different values of the
            coverage indicator are as follows :
        </p>
        <ul>
            <li>
                <div className={'coverage-high'}
                     style={{float: 'left', marginTop: '3px', height: covIndSize, width: covIndSize}}/>
                <span style={{float: 'left', marginLeft: '20px'}}>The coverage is higher than 90%</span>
            </li>
            <li>
                <div className={'coverage-medium'}
                     style={{float: 'left', marginTop: '3px', height: covIndSize, width: covIndSize}}/>
                <span style={{float: 'left', marginLeft: '20px'}}>The coverage is between 50% and 90%</span>
            </li>
            <li>
                <div className={'coverage-low'}
                     style={{float: 'left', marginTop: '3px', height: covIndSize, width: covIndSize}}/>
                <span style={{float: 'left', marginLeft: '20px'}}>The coverage is lower than 50%</span>
            </li>
        </ul>
    </>,
    "esgReport.sectorsSum.weight": <>
        <p className={'canopia'}><b>SENSITIVE SECTORS</b></p>
        <p>
            The importance of exposure is expressed by the color of the icon. It may be gray, red-empty, or red-filled.
            Red-filled exposures may have a negative impact on the final grade.</p>
        <ul>
            <li>
                <img key={"ALC"} alt={"ALC"} src={getIconPath("ALC", 'good')} width={ICON_SIZE} height={ICON_SIZE}
                     style={{marginRight: 4, marginBottom: 3}}/>: Exposure to alcohol is below 1%.
            </li>
            <li>
                <img key={"GBL"} alt={"GBL"} src={getIconPath("GBL", 'bad')} width={ICON_SIZE} height={ICON_SIZE}
                     style={{marginRight: 4, marginBottom: 3}}/>: Exposure to gambling is equal to or above 1%.
            </li>
            <li>
                <img key={"WPN"} alt={"WPN"} src={getIconPath("WPN", 'bad')} width={ICON_SIZE} height={ICON_SIZE}
                     style={{marginRight: 4, marginBottom: 3}}/>: Exposure to weapons is equal to or above 1% and may
                affect the final grade.
            </li>
        </ul>

    </>,
    "esgReport.normsSum.weight": <>
        <p className={'canopia'}><b>INTERNATIONAL NORMS</b></p>
        <p>
            The importance of exposure is expressed by the color of the icon. It may be gray or red-filled. Red-filled
            exposures may have a negative impact on the final grade.
        </p>
        <p>
            For international norms red-filled icons are shown when the sum of all exposure is equal to or above 1%. For
            example, if the exposures to Human rights and Corruption are respectively 0.5% and 0.7%, both categories
            will have a red icon, e.g. <img key={"HR"} alt={"HR"} src={getIconPath("HR", 'bad')} width={ICON_SIZE}
                                            height={ICON_SIZE}
                                            style={{marginRight: 4, marginBottom: 3}}/>
            <img key={"CR"} alt={"CR"} src={getIconPath("CR", 'bad')} width={ICON_SIZE} height={ICON_SIZE}
                 style={{marginBottom: 3}}/>.
        </p>
        <p>
            If the exposure to Human right and Corruption, on the other hand, were 0.3% and 0.5%, both will have a gray
            icon, e.g.
            <img key={"HR"} alt={"HR"} src={getIconPath("HR", 'good')} width={ICON_SIZE} height={ICON_SIZE}
                 style={{marginRight: 4, marginBottom: 3}}/>
            <img key={"CR"} alt={"CR"} src={getIconPath("CR", 'good')} width={ICON_SIZE} height={ICON_SIZE}
                 style={{marginBottom: 3}}/>.
        </p>
        <p>
            Cluster bombs and nuclear weapons (according to TPN treaty) are treated separately. This category will have
            a red-filled icon If the exposure is equal to or above 0.005%:
            <img key={"CB"} alt={"CB"} src={getIconPath("CB", 'bad')} width={ICON_SIZE} height={ICON_SIZE}
                 style={{marginBottom: 3}}/>.
        </p>
    </>,
    "esgReport.allPosImpactsSum.weight": <>
        <p className={'canopia'}><b>POSITIVE IMPACTS</b></p>
        <p>
            The gauge is the sum of exposure to clean tech, water, green and sustainable bonds and high impact funds.
        </p>
        <p>
            The gauge is scaled between 0% and 20%. Should the exposure exceed 20%, then a <Plus/> will be added
            to the gauge.
        </p>
        <p>
            A gauge of 20% or above may have a positive impact on the final grade.</p>
        <ul>
            <li>
                <div style={{marginLeft: '2px', display: 'flex', flexWrap: "nowrap", width: '100%'}}>
                    <div style={{width: 90, marginTop: "6px"}}>
                        <ProgressBar bsPrefix={"progress-canopia"} variant={"climate-pos"} now={0.22} max={0.2}/>
                    </div>
                    <div style={{marginRight: '15px'}}><Plus/></div>
                    Portfolio
                </div>
            </li>
            <li>
                <div style={{marginLeft: '2px', display: 'flex', flexWrap: "nowrap", width: '100%'}}>
                    <div style={{width: 90, marginTop: "6px"}}>
                        <ProgressBar bsPrefix={"progress-canopia"} variant={"co2-bm"} now={0.07} max={0.2}/>
                    </div>
                    <div style={{marginRight: '28px'}}></div>
                    Benchmark
                </div>
            </li>
        </ul>
    </>,
    "esgReport.negImpactsSum.weight": <>
        <p className={'canopia'}><b>NEGATIVE IMPACTS</b></p>
        <p>
            The gauge is the sum of exposure to Oil & Gas and Coal.
        </p>
        <p>
            Exposure is separated between companies deemed “in transition” and companies which are not. Companies “in
            transition” have committed to CO2 reductions target to limit the increase in global warming.
        </p>
        <p>
            The exposure to Oil & Gas and Coal in transition is shown in an empty red gauge.
        </p>
        <p>
            Exposure to companies not engaged in a decarbonization transition process is shown in filled-red gauge and
            may have a negative impact on the final grade.
        </p>
        <p>
            The gauge is scaled between 0% and 20%. Should the exposure exceed 20%, then a “+” will be added to the
            gauge.
        </p>
        <ul>
            <li>
                <div style={{marginLeft: '2px', display: 'flex', flexWrap: "nowrap", width: '100%'}}>
                    <div style={{width: 90, marginTop: "6px"}}>
                        <ProgressBar bsPrefix={"progress-canopia"}>
                            <ProgressBar bsPrefix={"progress-canopia-left"} variant={'climate-neg'} now={0.12} max={0.2}
                                         key={1}/>
                            <ProgressBar bsPrefix={"progress-canopia"} variant={'climate-neg-transition'} now={0.1}
                                         max={0.2} key={3}/>
                        </ProgressBar>
                    </div>
                    <div style={{marginRight: '15px'}}><Plus/></div>
                    Portfolio
                </div>
            </li>
            <li>
                <div style={{marginLeft: '2px', display: 'flex', flexWrap: "nowrap", width: '100%'}}>
                    <div style={{width: 90, marginTop: "6px"}}>
                        <ProgressBar bsPrefix={"progress-canopia"} variant={"co2-bm"} now={0.07} max={0.2}/>
                    </div>
                    <div style={{marginRight: '28px'}}></div>
                    Benchmark
                </div>
            </li>
        </ul>
    </>,
    "esgReport.thematicBondsSum.weight": <>
        {header}
        <ul>
            <li>Thematic bonds : percentage of bonds issued as green or sustainable.
            </li>
        </ul>
        {footer}
    </>,
    "esgReport.co2IntensityNorm": <>
        <p className={'canopia'}><b>{CO2} INTENSITY</b></p>
        <p>This section provides detailed information on the portfolio weighted exposure to {CO2} intensity.
            Intensity ({CO2} Total / Revenues) is calculated on scope 1 and 2 with units [t {CO2} / mio $].
        </p>
        {commonTextCO2}
    </>,
    "esgReport.co2EmissionsNorm": <>
        <p className={'canopia'}><b>{CO2} EMISSIONS</b></p>
        <p>This section provides detailed information on the portfolio weighted exposure to {CO2} emissions.
            Total emissions ({CO2} Total) is calculated on scope 1 and 2 with units [kt {CO2}].
        </p>
        {commonTextCO2}
    </>,
    "esgReport.netZeroPosSum.weight": <>
        <p className={'canopia'}><b>ALIGNED NET-ZERO</b></p>
        <p>
            The gauge is the sum of exposure to companies with validated (1.5°C, below 2°C, 2°C) or committed net zero
            targets.
        </p>
        <p>
            Exposure is separated between companies with validated net zero targets (filled-green gauge) or committed to
            net zero targets (empty-green gauge).
        </p>
        <ul>
            <li>
                <div style={{marginLeft: '2px', display: 'flex', flexWrap: "nowrap", width: '100%'}}>
                    <div style={{width: 90, marginTop: "6px"}}>
                        <ProgressBar bsPrefix={"progress-canopia"}>
                            <ProgressBar bsPrefix={"progress-canopia-left"} variant={'aligned'} now={0.12} max={0.2}
                                         key={1}/>
                            <ProgressBar bsPrefix={"progress-canopia"} variant={'aligned-partial'} now={0.1}
                                         max={0.2} key={3}/>
                        </ProgressBar>
                    </div>
                    <div style={{marginRight: '15px'}}></div>
                    Portfolio
                </div>
            </li>
            <li>
                <div style={{marginLeft: '2px', display: 'flex', flexWrap: "nowrap", width: '100%'}}>
                    <div style={{width: 90, marginTop: "6px"}}>
                        <ProgressBar bsPrefix={"progress-canopia"} variant={"co2-bm"} now={0.07} max={0.2}/>
                    </div>
                    <div style={{marginRight: '15px'}}></div>
                    Benchmark
                </div>
            </li>
        </ul>
    </>,
    "esgReport.netZeroNegSum.weight": <>
        <p className={'canopia'}><b>MISALIGNED NET-ZERO</b></p>
        <p>
            The gauge is the sum of exposure to companies analysed by TPI initiative that failed to be considered has
            having net zero targets aligned with a path below 2°C.
        </p>
        <p>
            Exposure is separated between companies not aligned with net zero targets or with no disclosure (filled-red
            gauge) or partially aligned to net zero targets (empty-red gauge).
        </p>
        <ul>
            <li>
                <div style={{marginLeft: '2px', display: 'flex', flexWrap: "nowrap", width: '100%'}}>
                    <div style={{width: 90, marginTop: "6px"}}>
                        <ProgressBar bsPrefix={"progress-canopia"}>
                            <ProgressBar bsPrefix={"progress-canopia-left"} variant={'misaligned'} now={0.12} max={0.2}
                                         key={1}/>
                            <ProgressBar bsPrefix={"progress-canopia"} variant={'misaligned-partial'} now={0.1}
                                         max={0.2} key={3}/>
                        </ProgressBar>
                    </div>
                    <div style={{marginRight: '15px'}}></div>
                    Portfolio
                </div>
            </li>
            <li>
                <div style={{marginLeft: '2px', display: 'flex', flexWrap: "nowrap", width: '100%'}}>
                    <div style={{width: 90, marginTop: "6px"}}>
                        <ProgressBar bsPrefix={"progress-canopia"} variant={"co2-bm"} now={0.07} max={0.2}/>
                    </div>
                    <div style={{marginRight: '15px'}}></div>
                    Benchmark
                </div>
            </li>
        </ul>
    </>,

    // Detail
    "detail.finalGradeHistory": <>
        <p>
            The chart displays the final grade of the selected portfolio and its benchmark. If the final grade has been
            adjusted and is different from the Consensus grade, an icon <Asterisk size={'0.8em'}/> will be shown next to
            it.
        </p>
    </>,
    "detail.consensus": <>
        <p className={'canopia'}><b>CONSENSUS RELATIVE POSITIONING</b></p>
        <p>
            The chart displays the positioning of the Consensus average and strength, which collectively determine the
            Consensus grade for:
        </p>
        <ul>
            <li>
                <div className={'consensus-icon-bm'} style={{display: "inline-flex"}}/>
                <span style={{marginLeft: '20px'}}>The benchmark of the selected portfolio</span>
            </li>
            <li>
                <div className={'consensus-icon-pf'} style={{display: "inline-flex"}}/>
                <span style={{marginLeft: '20px'}}>The selected portfolio</span>
            </li>
            <li>
                {subPfSpot}
                <span style={{marginLeft: '20px'}}>The sub portfolio(s) if any</span>
            </li>
        </ul>
        <p>
            The higher the average and strength, the better the Consensus grade. The bubble size of sub portfolios
            represents their contribution (based on their weight and coverage) to the selected portfolio Consensus
            grade.
        </p>
        <p>
            For easier readability, the chart's average axis (horizontal axis) ranges from 45% to 100%, and the strength
            axis (vertical axis) ranges from -15% to +45%.
        </p>

        <HiLightBulb style={{color: '#adad2f', marginRight: '8px'}} size={'1.6em'}/>Click on a sub
        portfolio {subPfSpot} on the chart or on the legend to change the selected portfolio.
    </>,
    "detail.coverageHistory": <>
        <p>
            The chart displays the coverage of the selected portfolio and its benchmark. The coverage may be above 100%
            in case the resulting cash amount is negative.
        </p>
    </>,
    "detail.exposure": <>
        <p>
            <HiLightBulb style={{color: '#adad2f', marginRight: '8px'}} size={'1.6em'}/>Click on a label below the chart
            to display its detail.
        </p>
    </>,
    // "detail.sectors": <>
    //     <p>
    //         <HiLightBulb style={{color: '#adad2f', marginRight: '8px'}} size={'1.6em'}/>Click on a sector label (e.g.
    //         Alcohol) to display its detail.
    //     </p>
    // </>,
    // "detail.norms": <>
    //     <p>
    //         <HiLightBulb style={{color: '#adad2f', marginRight: '8px'}} size={'1.6em'}/>Click on a norm label (e.g.
    //         Corruption) to display its detail.
    //     </p>
    // </>,
    // "detail.climatePos": <>
    //     <p>
    //         <HiLightBulb style={{color: '#adad2f', marginRight: '8px'}} size={'1.6em'}/>Click on a climate theme label (e.g.
    //         Clean Tech) to display its detail.
    //     </p>
    // </>,
    "detail.exposureContrib": <>
        <p>
            The chart represents the contribution (based on their weight and exposure) of the sub portfolios to the
            selected portfolio exposure.
        </p>
        <p>
            <HiLightBulb style={{color: '#adad2f', marginRight: '8px'}} size={'1.6em'}/>Click on a sub portfolio label
            to change the selected portfolio. To come back to one of the previous portfolio use the selection at the top
            of the window.
        </p>
    </>,
    "detail.exposure.co2Intensity": <>
        <p>
            The bars represent the weighted {CO2} intensity exposure and are calculated based on scope 1 and 2 emissions
            [t {CO2} / Million Revenues in $].
        </p>
        {co2IconsText}
    </>,
    "detail.exposure.co2Emissions": <>
        <p>
            The bars represent the weighted {CO2} emissions exposure and are calculated based on scope 1 and 2 emissions
            [kt {CO2}].
        </p>
        {co2IconsText}
    </>,

    // Direct lines
    issuer: <>
        <p className={'canopia'}><b>ISSUER</b></p>
        <p>The issuer is the final entity to which ESG information is linked. It can be a corporate or a public
            entity.</p>
    </>,
    type: <>
        <p className={'canopia'}><b>RATING TYPE</b></p>
        <p>Each position is identified with a standardized “type” to facilitate comparison between different funds or
            portfolios.</p>
    </>,
    consensusRank: <>
        <p className={'canopia'}><b>ESG CONSENSUS</b></p>
        <p>For each issuer, the ESG Consensus measures the ESG Quality (Above or Below) and the Consensus strength
            (Strong, Medium or Weak).</p>
        <p>The ESG Quality is “Above” when a majority of ESG sources/experts are giving a positive opinion to the
            issuer, or “Below”, when the majority of opinions are
            negative.</p>
        <p>A strong Consensus is representative of a convergence of opinions regarding the ESG Quality of an issuer. A
            weak Consensus shows an important dispersion of
            opinions.</p>
    </>,
    sectors: <>
        <p className={'canopia'}><b>SENSITIVE SECTORS</b></p>
        <p>The following sectors are screened:</p>
        <ul>
            <li><img key={"ALC"} alt={"ALC"} src={getIconPath("ALC", 'bad')} width={ICON_SIZE} height={ICON_SIZE}
                     style={{marginRight: 4, marginBottom: 3}}/> Alcohol
            </li>
            <li><img key={"GBL"} alt={"GBL"} src={getIconPath("GBL", 'bad')} width={ICON_SIZE} height={ICON_SIZE}
                     style={{marginRight: 4, marginBottom: 3}}/> Gambling
            </li>
            <li><img key={"GMO"} alt={"GMO"} src={getIconPath("GMO", 'bad')} width={ICON_SIZE} height={ICON_SIZE}
                     style={{marginRight: 4, marginBottom: 3}}/> GMO
            </li>
            <li><img key={"NUC"} alt={"NUC"} src={getIconPath("NUC", 'bad')} width={ICON_SIZE} height={ICON_SIZE}
                     style={{marginRight: 4, marginBottom: 3}}/> Nuclear
            </li>
            <li><img key={"PRN"} alt={"PRN"} src={getIconPath("PRN", 'bad')} width={ICON_SIZE} height={ICON_SIZE}
                     style={{marginRight: 4, marginBottom: 3}}/> Pornography
            </li>
            <li><img key={"TOB"} alt={"TOB"} src={getIconPath("TOB", 'bad')} width={ICON_SIZE} height={ICON_SIZE}
                     style={{marginRight: 4, marginBottom: 3}}/> Tobacco
            </li>
            <li><img key={"WPN"} alt={"WPN"} src={getIconPath("WPN", 'bad')} width={ICON_SIZE} height={ICON_SIZE}
                     style={{marginRight: 4, marginBottom: 3}}/> Weapon
            </li>
        </ul>
    </>,
    norms: <>
        <p className={'canopia'}><b>INTERNATIONAL NORMS</b></p>
        <p>The following norms are screened:</p>
        <ul>
            <li><img key={"BE"} alt={"BE"} src={getIconPath("BE", 'bad')} width={ICON_SIZE} height={ICON_SIZE}
                     style={{marginRight: 4, marginBottom: 3}}/> Business ethics
            </li>
            <li><img key={"CR"} alt={"CR"} src={getIconPath("CR", 'bad')} width={ICON_SIZE} height={ICON_SIZE}
                     style={{marginRight: 4, marginBottom: 3}}/> Corruption
            </li>
            <li><img key={"EN"} alt={"EN"} src={getIconPath("EN", 'bad')} width={ICON_SIZE} height={ICON_SIZE}
                     style={{marginRight: 4, marginBottom: 3}}/> Environment
            </li>
            <li><img key={"HR"} alt={"HR"} src={getIconPath("HR", 'bad')} width={ICON_SIZE} height={ICON_SIZE}
                     style={{marginRight: 4, marginBottom: 3}}/> Human rights
            </li>
            <li><img key={"OR"} alt={"OR"} src={getIconPath("OR", 'bad')} width={ICON_SIZE} height={ICON_SIZE}
                     style={{marginRight: 4, marginBottom: 3}}/> Oppressive
                regime
            </li>
            <li><img key={"CB"} alt={"CB"} src={getIconPath("CB", 'bad')} width={ICON_SIZE} height={ICON_SIZE}
                     style={{marginRight: 4, marginBottom: 3}}/> Cluster bombs &
                NPT
            </li>
        </ul>
    </>,
    impacts: <>
        <p className={'canopia'}><b>CLIMATE</b></p>
        <p>The following themes are screened:</p>
        <ul>
            <li><img key={"CT"} alt={"CT"} src={getIconPath("CT", 'bad')} width={ICON_SIZE} height={ICON_SIZE}
                     style={{marginRight: 4, marginBottom: 3}}/> Clean Tech
            </li>
            <li><img key={"WT"} alt={"WT"} src={getIconPath("WT", 'bad')} width={ICON_SIZE} height={ICON_SIZE}
                     style={{marginRight: 4, marginBottom: 3}}/> Water
            </li>
            <li><img key={"FF"} alt={"FF"} src={getIconPath("FF", 'bad')} width={ICON_SIZE} height={ICON_SIZE}
                     style={{marginRight: 4, marginBottom: 3}}/> Oil & Gas
            </li>
            <li><img key={"FFT"} alt={"FFT"} src={getIconPath("FFT", 'bad')} width={ICON_SIZE} height={ICON_SIZE}
                     style={{marginRight: 4, marginBottom: 3}}/> Oil & Gas
                transition
            </li>
            <li><img key={"FC"} alt={"FC"} src={getIconPath("FC", 'bad')} width={ICON_SIZE} height={ICON_SIZE}
                     style={{marginRight: 4, marginBottom: 3}}/> Coal
            </li>
            <li><img key={"FCT"} alt={"FCT"} src={getIconPath("FCT", 'bad')} width={ICON_SIZE} height={ICON_SIZE}
                     style={{marginRight: 4, marginBottom: 3}}/> Coal
                Transition
            </li>
        </ul>
    </>,
    thematicBonds: <>
        <p className={'canopia'}><b>THEMATIC BONDS</b></p>
        <p>The following bonds are screened:</p>
        <ul>
            <li><img key={"GB"} alt={"GB"} src={getIconPath("GB", 'bad')} width={ICON_SIZE} height={ICON_SIZE}
                     style={{marginRight: 4, marginBottom: 3}}/> Green Bond
            </li>
            <li><img key={"SB"} alt={"SB"} src={getIconPath("SB", 'bad')} width={ICON_SIZE} height={ICON_SIZE}
                     style={{marginRight: 4, marginBottom: 3}}/> Sustainable
                Bond
            </li>
        </ul>
    </>,
    'netZero.rank': <>
        <p className={'canopia'}><b>NET-ZERO ALIGNMENT</b></p>
        <p>The following alignments are screened:</p>
        <ul>
            <li>
                <div className={'aligned nowrap'} style={{marginBottom: 3}}>1.5°C</div>
            </li>
            <li>
                <div className={'aligned nowrap'} style={{marginBottom: 3}}>Well below 2°C</div>
            </li>
            <li>
                <div className={'aligned nowrap'} style={{marginBottom: 3}}>2°C</div>
            </li>
            <li>
                <div className={'aligned-partial nowrap'} style={{marginBottom: 3}}>Committed</div>
            </li>
            <li>
                <div className={'misaligned nowrap'} style={{marginBottom: 3}}>Not aligned</div>
            </li>
            <li>
                <div className={'misaligned nowrap'} style={{marginBottom: 3}}>No disclosure</div>
            </li>
            <li>
                <div className={'misaligned-partial nowrap'} style={{marginBottom: 3}}>Partially aligned</div>
            </li>
        </ul>
    </>,

    // REAL ESTATE
    sre: <>
        <p className={'canopia'}><b>SRE</b></p>
        <p>The Energy Reference Area (SRE), as defined in SIA 416/1:2007, is the sum of all floor areas of floors and
            basements that are included in the thermal envelope
            and whose use
            requires heating or cooling.</p>
    </>,
    // CO2
    co2IDC: <>
        <p className={'canopia'}><b>Energy use intensity (IDC)</b></p>
        <p>Measure of how much energy a building consumes in relation to its size or functional area [MJ / m2].</p>
    </>,
    totalCO2: <>
        <p className={'canopia'}><b>TOTAL {CO2}</b></p>
        <p>Total {CO2} scope 1+2 of buildings [kg {CO2}].</p>
    </>,
    totalCO2BySRE: <>
        <p className={'canopia'}><b>{CO2} / SRE</b></p>
        <p>Total {CO2} of buildings divided by energy reference area (SRE in French) <span
            className={'nowrap'}>[kg {CO2} / m2]</span>.</p>
    </>,
    totalCO2ByRevenues: <>
        <p className={'canopia'}><b>{CO2} / REVENUES</b></p>
        <p>Total {CO2} of buildings divided by rental revenues <span className={'nowrap'}>[t {CO2} / mio CHF]</span>.
        </p>
    </>,
    co2Reduction: <>
        <p className={'canopia'}><b>NEEDED {CO2} REDUCTION - TARGET 2030</b></p>
        <p>Percentage of reduction in {CO2} emissions needed to reach Swiss 2030 national target of maximum <span
            className={'nowrap'}>15 kg {CO2} / m2 [% kg {CO2} / m2]</span>.</p>
        <p>This indicator does not integrate future refurbishing investments.</p>
    </>,
    co2BySRETgt2030: <>
        <p className={'canopia'}><b>{CO2} / SRE - TARGET 2030</b></p>
        <p>This rating captures the target <span className={'nowrap'}>{CO2} / SRE</span> in 2030 by integrating all
            planned refurbishment investments until 2030.</p>
    </>,
    co2YearlyExcess: <>
        <p className={'canopia'}><b>YEARLY EXCESS {CO2} - TARGET 2050</b></p>
        <p>Yearly average {CO2} emissions <span className={'nowrap'}>[kg {CO2} / m2 / year]</span> above or below Swiss
            national {CO2} emissions targets of <span
                className={'nowrap'}>2030 (15 kg {CO2} / m2)</span> and <span
                className={'nowrap'}>2050 (5 kg {CO2} / m2)</span>.</p>
        <p>This indicator integrates planned refurbishment investments until 2050.</p>
    </>,
    co2ExcessTotal: <>
        <p className={'canopia'}><b>EXCESS TOTAL {CO2} UNTIL 2050</b></p>
        <p>Cumulative total {CO2} emissions [kg {CO2}] above or below Swiss national {CO2} emissions targets of <span
            className={'nowrap'}>2030 (15 kg {CO2} / m2)</span> and <span
            className={'nowrap'}>2050 (5 kg {CO2} / m2)</span>.</p>
        <p>This indicator integrates planned refurbishment investments until 2050.</p>
    </>,
    // Indicators
    ratingCO2Total: <>
        <p className={'canopia'}><b>RATING ACTUAL {CO2} PERFORMANCE</b></p>
        <p>This rating captures the actual performance of the {CO2} emissions of buildings according to Swiss SIA
            norms.</p>
    </>,
    ratingCO2Heating: <>
        <p className={'canopia'}><b>RATING ACTUAL HEATING PERFORMANCE</b></p>
        <p>This rating captures the actual heating performance of buildings compared to peers.</p>
    </>,
    ratingObsolescence: <>
        <p className={'canopia'}><b>RATING OBSOLESCENCE</b></p>
        <p>This rating captures obsolescence of buildings compared to peers based on Signa-Terre evaluations.</p>
    </>,
    ratingCO2Needed2030: <>
        <p className={'canopia'}><b>RATING {CO2} NEEDED 2030</b></p>
        <p>This rating captures the gap between actual {CO2} emissions compared to Swiss national target in <span
            className={'nowrap'}>2030 (15 kg {CO2} / m2)</span>.</p>
    </>,
    ratingCO2Planned2030: <>
        <p className={'canopia'}><b>RATING {CO2} PLANNED 2030</b></p>
        <p>This rating captures the excess of {CO2} emissions of buildings compared to future {CO2} emissions in line
            with Swiss national objectives of <span
                className={'nowrap'}>2030 (15 kg {CO2} / m2)</span> and <span
                className={'nowrap'}>2050 (5 kg {CO2} /m2)</span>.</p>
        <p>This indicator integrates the planned future refurbishments investments.</p>
    </>,
    ratingCO2Planned2050: <>
        <p className={'canopia'}><b>RATING {CO2} PLANNED 2050</b></p>
        <p>This rating captures the excess of {CO2} emissions of buildings compared to future {CO2} emissions in line
            with Swiss national objectives of <span
                className={'nowrap'}>2030 (15 kg {CO2} / m2)</span> and <span
                className={'nowrap'}>2050 (5 kg {CO2} /m2)</span>.</p>
        <p>This indicator integrates the planned future refurbishments investments.</p>
    </>
}

export const getSubRowsForHisto = (parentRows) => {
    let subRows = [];
    parentRows.forEach(pfRow => {
        pfRow.subRows.forEach(subRow => {
            let categoryExists = false;
            subRows.forEach(tmpRow => {
                if (tmpRow.category.toLowerCase() === subRow.category.toLowerCase()) {
                    tmpRow.value.push(subRow);
                    categoryExists = true;
                }
            });
            if (!categoryExists) {
                subRows.push({category: subRow.category, key: subRow.key, value: [subRow]});
            }
        });
    });
    return subRows;
}

export const getSubRowsForCategory = (parentRows, subCategory) => {
    let subRows = [];
    parentRows.forEach(pfRow => {
        const subCatRow = pfRow.subRows.find(subRow =>
            subCategory.toLowerCase() === subRow.category.toLowerCase()
        );
        subRows.push(subCatRow);
    });
    return subRows;
}

export const getGradeClass = (rating) => {
    switch (rating) {
        case "A+":
            return "esg-grade-ah";
        case "A ":
        case "A-":
            return "esg-grade-a";
        case "B+":
        case "B ":
        case "B-":
            return "esg-grade-b";
        case "C+":
        case "C ":
        case "C-":
            return "esg-grade-c";
        case "D+":
        case "D ":
        case "D-":
            return "esg-grade-d";
        case "NR":
            return "esg-grade-nr";
        default:
            return "";
    }
}

export const getAboveBelow = (grade) => {
    switch (grade) {
        case "A":
        case "B":
            return "Above";
        case "C":
        case "D":
            return "Below";
        case "NR":
            return "NR";
        default:
            return "";
    }
}

export const getAboveBelowColor = (grade) => {
    switch (grade) {
        case "A":
            return "#00B140";
        case "B":
            return "#80D8A0";
        case "C":
            return "#E48897";
        case "D":
            return "#c8102e";
        case "NR":
            return "#99999b";
        default:
            return "";
    }
}

export const compare = (a, b, sort) => {
    if (sort === "asc") {
        return compareAsc(a, b, 1);
    } else {
        return compareAsc(b, a, -1);
    }
}

export const compareAsc = (a, b, sign) => {
    // Always put empty values at the bottom
    if (isEmpty(a) && !isEmpty(b)) {
        return 2 * sign;
    }
    if (!isEmpty(a) && isEmpty(b)) {
        return -2 * sign;
    }
    if (isEmpty(a) && isEmpty(b)) {
        return 0;
    }
    let newA = a;
    if (typeof a === 'string' || a instanceof String) {
        newA = a.toLowerCase();
    }
    if (a instanceof Array) {
        newA = a.join();
    }
    let newB = b;
    if (typeof b === 'string' || b instanceof String) {
        newB = b.toLowerCase();
    }
    if (b instanceof Array) {
        newB = b.join();
    }
    return (newA < newB) ? -1 : (newA > newB) ? 1 : 0;
}

export const isEmpty = (a) => {
    return a == null || //
        (typeof a === 'string' && a.trim() === '') || //
        (a instanceof Array && a.length === 0) || //
        (typeof a === 'number' && Number.isNaN(a));
}

export const getAmountValue = (data, selectedAmountField) => {
    switch (selectedAmountField.key) {
        case AMOUNT_CONTROLS[0].key:
            return data.amountFmt;
        case AMOUNT_CONTROLS[1].key:
            return data.absWgtFmt;
        case AMOUNT_CONTROLS[2].key:
            return data.relWgtFmt;
        default:
            return '';
    }
}

export const getCO2Value = (data, selectedAmountField) => {
    switch (selectedAmountField.key) {
        case AMOUNT_CONTROLS[1].key:
            return data.absWgtFmt;
        case AMOUNT_CONTROLS[0].key:
        case AMOUNT_CONTROLS[2].key:
            return data.relWgtFmt;
        default:
            return '';
    }
}

export const getAmountValueForFunds = (pfRow, selectedAmountField) => {
    switch (selectedAmountField.key) {
        case AMOUNT_CONTROLS[0].key:
            return pfRow.amountFmt;
        case AMOUNT_CONTROLS[1].key:
            return pfRow.weightFmt;
        default:
            return '';
    }
}

export const findPeriodicity = (periodicityKey) => {
    let result = null;
    PERIODICITY_VALUES.forEach(periodicityValue => {
        if (periodicityValue.key === periodicityKey) {
            result = periodicityValue;
        }
    });
    return result;
}

export const parseNull = (elem) => {
    return !elem || elem.length === 0 ? "(empty)" : elem;
}

export const sortByProp = (dataToSort, col, order) => {
    let cols = col.split('.');
    let nbCols = cols.length;
    dataToSort.sort(function (a, b) {
        return compare(getProp(a, cols, nbCols), getProp(b, cols, nbCols), order);
    });
}

export const getProp = (item, cols, nbCols) => {
    let propI = item[cols[0]];
    if (nbCols === 1 || !propI) {
        return propI;
    }
    for (let i = 1; i < nbCols; i++) {
        propI = propI[cols[i]];
        if (!propI) {
            return propI;
        }
    }
    return propI;
}

export const getNextSort = (prevSort) => {
    return prevSort === "asc" ? "desc" : "asc";
}

export const getNextFilterIndex = (filterCols) => {
    const values = Object.values(filterCols).map(str => {
        return Number(str);
    });
    return Math.max(...values) + 1;
}

export const arrayContainsElements = (a, b) => {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        b.every(v => a.includes(v));
}

export const filter = (state, tgtFilterCol, filterCols, selectedValues, initialSortStatus) => {
    state.filterSelectedValues[tgtFilterCol] = selectedValues;

    let filteredDirectLines = [];
    const tgtIndex = filterCols[tgtFilterCol];

    if (arrayContainsElements(selectedValues, state.filterValues[tgtFilterCol])) {
        // Filter disabled (all values selected)
        // Left-shift the filter indexes higher than the target one
        Object.keys(filterCols).forEach(filterCol => {
            if (filterCols[filterCol] > tgtIndex) {
                filterCols[filterCol] = filterCols[filterCol] - 1;
            }
        });
        // And reset its filter index
        filterCols[tgtFilterCol] = 0;
        state.filterSelectedValues[tgtFilterCol] = state.filterValuesOrig[tgtFilterCol];
    } else {
        // Set the index of this new filter
        if (filterCols[tgtFilterCol] === 0) {
            filterCols[tgtFilterCol] = getNextFilterIndex(filterCols);
        }
    }

    // Refresh the filter values
    const newValues = {};
    newValues[tgtFilterCol] = state.filterValues[tgtFilterCol];
    Object.keys(filterCols).forEach(filterCol => {
        if (filterCols[tgtFilterCol] === 0 && filterCols[filterCol] === tgtIndex - 1) {
            newValues[filterCol] = state.filterValuesOrig[filterCol];
        } else if (filterCol !== tgtFilterCol) {
            newValues[filterCol] = [];
        }
    });

    // Filter out the lines from the current filter
    state.tgtDataOrig.forEach(dLine => {
        let add = true;
        Object.keys(filterCols).forEach(filterCol => {
            if (filterCols[filterCol] > 0) {
                let cols = filterCol.split('.');
                let nbCols = cols.length;
                const value = parseNull(getProp(dLine, cols, nbCols));
                if (!state.filterSelectedValues[filterCol].includes(value)) {
                    add = false;
                }
            }
        });
        if (add) {
            filteredDirectLines.push(dLine);

            Object.keys(filterCols).forEach(otherCol => {
                if (otherCol !== tgtFilterCol) {
                    let cols = otherCol.split('.');
                    let nbCols = cols.length;
                    const otherValue = parseNull(getProp(dLine, cols, nbCols));
                    if (!newValues[otherCol].includes(otherValue)) {
                        newValues[otherCol].push(otherValue);
                    }
                }
            });
        }
    });
    Object.keys(newValues).forEach(filterCol => {
        newValues[filterCol].sort();
    });
    state.filterValues = newValues;
    state.tgtData = filteredDirectLines;

    // Sort
    let prevSort = state.sortStatus[state.sortCol];
    state.sortStatus[state.sortCol] = getNextSort(prevSort);
    sort(state, state.sortCol, initialSortStatus);
}

export const sort = (state, col, initialSortStatus) => {
    let prevSort = state.sortStatus[col];
    let curSort = getNextSort(prevSort);
    let dataToSort = state.tgtData;

    sortByProp(dataToSort, col, curSort);

    state.tgtData = dataToSort;
    // Reset other cols
    state.sortStatus = {...initialSortStatus};
    state.sortStatus[col] = curSort;
    state.sortCol = col;
}

export const getAllPosImpacts = (esgReport) => {
    const posImpacts = esgReport.posImpacts;
    const thematicBonds = esgReport.thematicBonds;
    const highImpacts = esgReport.highImpacts;
    let all = [];
    if (posImpacts) {
        all = all.concat(posImpacts);
    }
    if (thematicBonds) {
        all = all.concat(thematicBonds);
    }
    if (highImpacts && highImpacts.length) {
        all = all.concat(esgReport.highImpactsSum);
    }
    return all;
}

export const getGradeFromRank = (value) => {
    switch (value) {
        case 2:
            return 'D';
        case 3:
            return 'C-';
        case 4:
            return 'C';
        case 5:
            return 'C+';
        case 6:
            return 'B-';
        case 7:
            return 'B';
        case 8:
            return 'B+';
        case 9:
            return 'A-';
        case 10:
            return 'A';
        case 11:
            return 'A+';
        default:
            // return 'NR';
            return null;
    }
}

export const getRatingColors = (ratingRank) => {
    if (ratingRank >= 11) {
        return {color: "#00b140", lightColor: "#4dc879", fontColor: "#fff"};
    } else if (ratingRank >= 9) {
        return {color: "#97d700", lightColor: "#b6e34d", fontColor: "#fff"};
    } else if (ratingRank >= 6) {
        return {color: "#ffd100", lightColor: "#ffdf4d", fontColor: "#fff"};
        // return {color: "#feb702", lightColor: "#ffdf4d", fontColor: "#fff"}; // Better contrast
        // return {color: "#E6AC02", lightColor: "#ffdf4d", fontColor: "#fff"}; // Strong contrast
    } else if (ratingRank >= 3) {
        return {color: "#fe5000", lightColor: "#fe844d", fontColor: "#fff"};
    } else if (ratingRank >= 0) {
        return {color: "#c8102e", lightColor: "#d9586d", fontColor: "#fff"};
    } else {
        return {color: "#99999b", lightColor: "#b8b8b9", fontColor: "#fff"};
    }
}

export const hasCoverage = (esgReport) => {
    return esgReport && esgReport.coverage > 0;
}

export const getValue = (data, prop) => {
    return data && data[prop] ? data[prop] === 0 ? null : data[prop] : null;
}

export const formatPct = (value) => {
    return new Intl.NumberFormat('fr-CH', {
        style: 'percent',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
    }).format(value);
}