import React, {useCallback, useEffect, useState} from "react";
import ajaxloader from "../../images/ajax-loader.gif";
import {Card, DropdownButton, FormCheck} from "react-bootstrap";
import AdminService from "../../services/admin.service";
import DropdownItem from "react-bootstrap/DropdownItem";
import {useDispatch, useSelector} from "react-redux";
import {loadAllClients, selectUserState} from "../../reducer/userSlice";
import CreatableSelect from "react-select/creatable";
import {loadDates, selectDateState} from "../../reducer/dateSlice";

export default function Run(props) {

    const dispatch = useDispatch();

    const {allClients} = useSelector(selectUserState);
    const {dates, dateStatus, dateError} = useSelector(selectDateState);

    const reviewTypes = ['POS_ID', 'ISIN_AND_NAME'];

    const [clientIds, setClientIds] = useState([]);
    const [date, setDate] = useState('');
    const [consensusId, setConsensusId] = useState('');
    const [unpublish, setUnpublish] = useState(false);
    const [runType, setRunType] = useState('full_eval_initial_run');
    const [reviewType, setReviewType] = useState(reviewTypes[0]);
    const [reviewFileName, setReviewFileName] = useState('');
    const [skipReview, setSkipReview] = useState(false);

    const [dateOptions, setDateOptions] = useState([]);

    const [loadStatus, setLoadStatus] = useState("idle");
    const [loadMessage, setLoadMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState(null);
    const [errorName, setErrorName] = useState(null);
    const [errors, setErrors] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const load = useCallback(() => {
        dispatch(loadAllClients());
    }, [dispatch]);

    // Load all the clients to populate the dropdown (done only once)
    useEffect(() => {
        if (loadStatus === "idle") {
            setLoadStatus("pending");
            try {
                load();
            } catch (err) {
                setLoadStatus("error");
                setLoadMessage("An error occurred while loading the page");
            } finally {
                setLoadStatus("success");
            }
        }

        if (dateStatus === "idle") {
            dispatch(loadDates());
        }
    }, [loadStatus, dateStatus, load, dispatch]);

    useEffect(() => {
        let optionsTmp = [];

        dates.forEach(date => {
            const option = {value: date, label: date};
            optionsTmp.push(option);
        });

        setDateOptions(optionsTmp);
    }, [dates]);

    const handleRun = (e) => {
        e.preventDefault();

        setLoading(true);
        setMessages(null);
        setErrorName(null);
        setErrors(null);
        setErrorMessage(null);

        AdminService.run(clientIds, date.value, consensusId, unpublish, runType, reviewType, reviewFileName, skipReview)
            .then(
                response => {
                    setMessages(response.data ? response.data.data : null);
                    setLoading(false);
                },
                error => {
                    setLoading(false);
                    processError(error);
                }
            );
    }

    const processError = (error) => {
        let response = error.response;

        let errorName;
        let errors;
        let errorMessage;
        if (response &&
            response.data &&
            response.data.error &&
            response.data.message) {
            errorName = response.data.error;
            errors = response.data.errors;
            errorMessage = response.data.message;
        } else {
            errorName = 'Service unavailable';
            errorMessage = 'We apologize for the inconvenience, our team is working on solving the issue. Please try to come back in a few minutes.\nThank you for your patience.';
            errors = '';
            if (error.message) {
                console.log(error.message);
            }
        }

        setErrorName(errorName);
        setErrors(errors);
        setErrorMessage(errorMessage);
    }

    const changeRunType = (val) => {
        setRunType(val);
    }

    let content;
    if (loadStatus === "error") {
        content = <div className="main-content">
            <header className="jumbotron">
                <h3 className='canopia'>Error</h3>
                <p>{loadMessage}</p>
            </header>
        </div>
    } else if (loadStatus !== "success") {
        content = <div><br/><img alt="loading" src={ajaxloader}/></div>;
    } else {

        let clients = [];

        allClients.forEach(client => {
            const clientId = client.id;
            const clientName = client.name;
            // let checked = selectedUserClients.includes(clientIds);
            clients.push({value: clientId, label: clientName + " (" + clientId + ")"});
        });

        const handleSelectClient = (clients, action) => {
            let tgtCientIds = [];
            clients.forEach(client => {
                tgtCientIds.push(client.value);
            });
            setClientIds(tgtCientIds);
            // setClientIds([...clientIds, value]);
            // switch (action) {
            //     // case 'select-option':
            //     // case 'set-value':
            //     case 'deselect-option':
            //     case 'remove-value':
            //     case 'pop-value':
            //         setClientIds(clientIds.filter(item => item !== value));
            //         break;
            //     case 'clear':
            //        setClientIds([]);
            // }
        }

        const handleDateChange = (date, action) => {
            setDate(date);
        }

        const changeUnpublishChecked = () => {
            setUnpublish(!unpublish);
        }

        const changeSkipReview = () => {
            setSkipReview(!skipReview);
        }

        content = <Card style={{width: '75rem', margin: 'auto'}}>
            <Card.Body>
                <Card.Title className='canopia'>Run - Evaluate a portfolio on a specific date</Card.Title>

                <form onSubmit={handleRun}>
                    <h5 style={{color: "red", marginTop: "15px"}}>If you plan to use a new consensus (freshly created in
                        the database), make sure to refresh the cache first by ticking the checkbox.</h5>
                    <br/>
                    <div className="form-group">
                        <label>Client ID</label>
                        <CreatableSelect options={clients}
                                         isClearable
                                         isSearchable={true}
                                         closeMenuOnSelect={false}
                                         onChange={handleSelectClient}
                                         isMulti/>
                        {/*<input value={clientIds}*/}
                        {/*       placeholder="e.g. 123"*/}
                        {/*       className={'form-control'}*/}
                        {/*       style={{width: "180px"}}*/}
                        {/*       aria-label="Client ID"*/}
                        {/*       onChange={(event) => setClientId(event.target.value)}*/}
                        {/*       aria-describedby="basic-addon2"/>*/}
                    </div>
                    <br/>
                    <div className="form-group">
                        <label>Pf Date (yyyy-MM-dd)</label>
                        <CreatableSelect options={dateOptions}
                                         placeholder={'yyyy-MM-dd'}
                                         name={'date-select'} //
                                         value={date}
                                         closeMenuOnSelect={true}
                                         onChange={handleDateChange}/>
                    </div>
                    <br/>
                    <div className="form-group">
                        <label>Consensus ID</label>
                        <input value={consensusId}
                               placeholder="e.g. 123"
                               className={'form-control'}
                               style={{width: "180px"}}
                               aria-label="Consensus ID"
                               onChange={(event) => setConsensusId(event.target.value)}
                               aria-describedby="basic-addon2"/>
                    </div>
                    <br/>
                    <div className="form-group">
                        <label>Unpublish</label>
                        <input style={{marginLeft: '1rem'}} type="checkbox" name={"unpublish-cb"}
                               onChange={changeUnpublishChecked}
                               checked={unpublish}/>
                    </div>
                    <br/>
                    <div className="form-group">
                        <label>Run type</label>
                        <div style={{margin: '15px'}}>
                            <FormCheck type="radio" label="Initial"
                                       checked={runType === "full_eval_initial_run"}
                                       onChange={() => changeRunType("full_eval_initial_run")}/>
                            <FormCheck type="radio" label="Review"
                                       checked={runType === "full_eval_final_run"}
                                       onChange={() => changeRunType("full_eval_final_run")}/>
                            <FormCheck type="radio" label="CO2 update"
                                       checked={runType === "co2_update"}
                                       onChange={() => changeRunType("co2_update")}/>
                        </div>
                    </div>

                    {runType === "full_eval_final_run" && <>
                        <div className="form-group">
                            <p>
                                <span style={{marginRight: "5px"}}>
                                    <input type="checkbox" name={"conser-cb"}
                                           onChange={changeSkipReview}
                                           checked={skipReview}/>
                                </span>
                                Skip the review step, use the current matching in the positions (reviewed or not).
                                Typical usage: when one wants to keep the same matching but use another consensus.
                            </p>
                        </div>
                        <br/>
                        <p>The review run requires 2 more parameters:</p>
                        <ul>
                            <li>The filename of the review made by Conser</li>
                            <li>The type of review to apply: By "position ID" if available or by "ISIN and name" in case
                                you need to apply the review made for another client ID
                            </li>
                        </ul>
                        <div className="form-group">
                            <label>Review Filename (leave it blank if there is only 1 file available in the
                                directory)</label>
                            <input value={reviewFileName}
                                   placeholder="e.g. Demo Global Portfolio By Asset Class_20221230 RevisedJL.csv"
                                   className={'form-control'}
                                   aria-label="Review Filename"
                                   onChange={(event) => setReviewFileName(event.target.value)}
                                   aria-describedby="basic-addon2"/>
                        </div>
                        <br/>
                        <div className="form-group">
                            <label>Review Type</label>
                            <DropdownButton id="dropdown-basic-button" title={reviewType} value={reviewType}
                                            variant={'default-dropdown'}>
                                {reviewTypes.map(v => (
                                    v !== reviewType && <DropdownItem key={v} value={v} onClick={() => setReviewType(v)}
                                                                      bsPrefix={'canopia-dropdown-item'}>
                                        <span style={{fontWeight: 'normal'}}>{v}</span>
                                    </DropdownItem>
                                ))}
                            </DropdownButton>
                        </div>
                    </>}
                    <br/>
                    <div className="form-group">
                        <button className="btn btn-primary btn-block" disabled={loading}>
                            {loading && (
                                <span className="spinner-border spinner-border-sm" style={{marginRight: '5px'}}/>
                            )}
                            <span>Run</span>
                        </button>
                    </div>
                    <br/>

                    {loading &&
                        <p style={{color: "red"}}>Please do not refresh the page, the process may take some time</p>}

                    {messages && (
                        <div className="form-group">
                            <br/>
                            <div className={"alert alert-info"} role="alert">
                                {messages.map(message => {
                                    return <>{message}<br/></>
                                })}
                            </div>
                        </div>
                    )}

                    {errorName && (
                        <div className="form-group">
                            <div className={"alert alert-danger"} role="alert">
                                <h3 className='canopia'>{errorName}</h3>
                                {errorMessage}
                                {errors && errors.map(error => {
                                    return <li key={error.field}>{error.defaultMessage && error.defaultMessage}</li>
                                })
                                }
                            </div>
                        </div>
                    )}
                </form>
            </Card.Body>
        </Card>
    }

    return (
        <div className="main-content">
            {content}
        </div>
    );
}
